import { AiOutlineGithub } from "react-icons/ai";
import { BsLinkedin } from "react-icons/bs";
import { AiOutlineTwitter } from "react-icons/ai";
import PropTypes from "prop-types";

export function Socials({ user_socials, contribution = "" }) {
  return (
    <div className="my-5 md:text-left flex flex-wrap gap-1 items-center overflow-hidden">
      {user_socials && user_socials.length > 0 ? (
        user_socials
          .filter(
            (social) =>
              social.social_value !== "false" &&
              social.social_value !== "True" &&
              social.social_value !== null &&
              social.social_value
          )
          .map(({ social_type, social_value }, index) => (
            <a
              key={index}
              href={
                social_type === "github"
                  ? `https://github.com/${social_value
                      .replace(/^https:\/\/github\.com\//, "")
                      .replace(/^github\.com\//, "")
                      .replace(/^@/, "")}`
                  : social_type === "twitter"
                  ? `https://twitter.com/${social_value
                      .replace(/^https:\/\/twitter\.com\//, "")
                      .replace(/^@/, "")}`
                  : social_type === "linkedin"
                  ? social_value.startsWith("https://www.linkedin.com/in/") ||
                    social_value.startsWith("http://www.linkedin.com/in/")
                    ? social_value
                    : `https://linkedin.com/in/${social_value}`
                  : social_value
              }
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer mx-1"
            >
              {social_type === "github" ? (
                <div className="text-white bg-[#161b22] px-4 py-1 rounded-full flex gap-1 items-center h-8">
                  <AiOutlineGithub size={20} />
                  <p className="text-xs capitalize">
                    {social_type} {contribution}
                  </p>
                </div>
              ) : social_type === "linkedin" ? (
                <div className="text-white bg-[#0b66c3] px-4 py-2 text-xs rounded-full flex gap-1 items-center h-8">
                  <BsLinkedin size={18} />
                  <p className="text-xs capitalize">{social_type}</p>
                </div>
              ) : social_type === "twitter" ? (
                <div className="bg-[#0b66c3] text-white px-4 py-1 rounded-full flex gap-1 items-center text-xs h-8">
                  <AiOutlineTwitter size={22} />
                  <p className="text-xs capitalize">{social_type}</p>
                </div>
              ) : (
                ""
              )}
            </a>
          ))
      ) : (
        <p className=" text-gray-500">No socials added.</p>
      )}
    </div>
  );
}

Socials.propTypes = {
  user_socials: PropTypes.array,
  contribution: PropTypes.string,
};
