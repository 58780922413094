import PropTypes from "prop-types";
export function SkillBadge({ skill, clickable = false, handleClick }) {
  return (
    <span
      className="rounded-full border-[1px] border-black px-2 md:px-4 py-1 text-xs md:text-sm cursor-pointer"
      onClick={() => {
        clickable && handleClick();
      }}
    >
      {skill}
    </span>
  );
}

SkillBadge.propTypes = {
  skill: PropTypes.string,
};
