import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Footer } from "components";
import PageLoader from "components/ui/Loader/PageLoader";
import FeedbackDetails from "pages/Mgt/FeedbackDetails";
import Rating from "pages/Recruiter/Rating";
const Home = lazy(() => import("pages/shared/Home"));
const Premium = lazy(() => import("pages/Recruiter/Premium"));
const Login = lazy(() => import("pages/Developer/Login"));
const RecruiterLogin = lazy(() => import("pages/Recruiter/login"));
const GetStarted = lazy(() => import("pages/Developer/GetStarted"));
const InsightifyPrivacyPolicy = lazy(() => import("pages/shared/InsightifyPrivacyPolicy"));
const NotFound = lazy(() => import("pages/shared/NotFound"));
const ResetPassword = lazy(() => import("pages/shared/ResetPassword"));
const ForgotPassword = lazy(() => import("pages/shared/ForgotPassword"));
const PrivateRoute = lazy(() => import("./routes/PrivateRoute"));
const RecruiterSignUp = lazy(() => import("./pages/Recruiter/RecruiterSignUp"));
const SearchedDevs = lazy(() => import("./components/to-be-cleaned/SearchedDevs"));
const ViewJob = lazy(() => import("pages/Developer/ViewJob"));
const GoPro = lazy(() => import("pages/Developer/GoPro"));
const DeveloperDetails = lazy(() => import("pages/Mgt/DeveloperDetails"));
const MgtSignup = lazy(() => import("pages/Org/OrgSignup"));
const SupportTickets = lazy(() => import("pages/CSU/SupportTickets"));
const JobDetails = lazy(() => import("pages/Recruiter/JobDetails"));
const AddJobPosting = lazy(() => import("pages/Recruiter/AddJobPosting"));
const Jobs = lazy(() => import("pages/Developer/Jobs"));
const RecruiterJobs = lazy(() => import("pages/Recruiter/Jobs"));
const EditJob = lazy(() => import("pages/Recruiter/EditJob"));
const Watchlist = lazy(() => import("pages/Developer/Watchlist"));
const JobApplication = lazy(() => import("pages/Developer/JobApplication"));
const Recruiters = lazy(() => import("pages/Mgt/Recruiters"));
const MgtDevelopers = lazy(() => import("pages/Mgt/MgtDevelopers"));
const RecruiterDetails = lazy(() => import("pages/Mgt/RecruiterDetails"));
const CustomerSupport = lazy(() => import("pages/Mgt/CustomerSupport"));
const Schedules = lazy(() => import("pages/Mgt/Schedules"));
const ScheduleDetails = lazy(() => import("pages/Mgt/ScheduleDetails"));
const BulkEmails = lazy(() => import("pages/Mgt/BulkEmails"));
const AddSupport = lazy(() => import("pages/Mgt/AddSupport"));
const Profile = lazy(() => import("pages/shared/Profile"));
const CSUDetails = lazy(() => import("pages/Mgt/CSUDetails"));
const Documents = lazy(() => import("pages/CSU/Documents"));
const SearchedDevs2 = lazy(() => import("components/to-be-cleaned/SearchedDevs2"));
const MyScheduleDetails = lazy(() => import("pages/Developer/MySchedules"));
const MgtPro = lazy(() => import("pages/Mgt/MgtPro"));
const ProDetails = lazy(() => import("pages/Mgt/ProDetails"));
const Navbar = lazy(() => import("components/ui/Navbar"));
const Feedback = lazy(() => import("pages/Developer/Feedback"));
const MgtRating = lazy(() => import("pages/Mgt/MgtRating"));
const RatingDetails = lazy(() => import("pages/Mgt/RatingDetails"));
const MgtFeedback = lazy(() => import("pages/Mgt/Feedback"));
const VerifyRecruiter = lazy(() => import("pages/Recruiter/Verify"));
const RecruiterFeedback = lazy(() => import("pages/Recruiter/Feedback"));
const CompareDev = lazy(() => import("pages/Mgt/CompareDev"));
const ReferProgram = lazy(() => import("pages/Developer/ReferProgram"));

function App() {
  const [mobileMenu, setMobileMenu] = useState(false);

  useEffect(() => {
    const hideMenu = () => {
      if (window.innerWidth > 900 && mobileMenu) {
        setMobileMenu(false);
      }
    };
    window.addEventListener("resize", hideMenu);
    return () => {
      window.removeEventListener("resize", hideMenu);
    };
  });

  return (
    <BrowserRouter className="bg-red-500">
      <ToastContainer />
      <Navbar />
      <Suspense fallback={<PageLoader />}>
        <Routes>
          {/* onboarding  */}
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/recruiter/login" element={<RecruiterLogin />} />
          <Route path="/password/renew" element={<ForgotPassword />} />
          <Route path="/password/reset" element={<ResetPassword />} />
          <Route path="/recruiter/sign-up" element={<RecruiterSignUp />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/insightify-privacy-policy" element={<InsightifyPrivacyPolicy />} />
          <Route path="/searched/:skill/:location" element={<SearchedDevs />} />
          <Route path="/management/signup" element={<MgtSignup />} />
          <Route path="/searched/:skill/:skill2/:location" element={<SearchedDevs2 />} />

          {/* developers routes */}
          <Route
            path="/profile/pro"
            element={
              <PrivateRoute>
                <GoPro />
              </PrivateRoute>
            }
          />
          <Route
            path="/watchlist"
            element={
              <PrivateRoute>
                <Watchlist />
              </PrivateRoute>
            }
          />
          <Route
            path="/developer/jobs"
            element={
              <PrivateRoute>
                <Jobs />
              </PrivateRoute>
            }
          />
          <Route
            path="/developer/jobs/:id"
            element={
              <PrivateRoute>
                <ViewJob />
              </PrivateRoute>
            }
          />
          <Route
            path="/developer/jobs/:id/submit-application"
            element={
              <PrivateRoute>
                <JobApplication />
              </PrivateRoute>
            }
          />
          <Route
            path="/developer/schedules/:id"
            element={
              <PrivateRoute>
                <MyScheduleDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/developer/feedback"
            element={
              <PrivateRoute>
                <Feedback />
              </PrivateRoute>
            }
          />

          <Route
            path="/developer/referral-program"
            element={
              <PrivateRoute>
                <ReferProgram />
              </PrivateRoute>
            }
          />

          {/* CSU routes  */}
          <Route
            path="/customer-support/documents"
            element={
              <PrivateRoute>
                <Documents />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer-support/tickets"
            element={
              <PrivateRoute>
                <SupportTickets />
              </PrivateRoute>
            }
          />

          {/* recruiter routes  */}
          <Route path="/developers/:id" element={<DeveloperDetails />} />
          <Route path="/developers/:id/compare" element={<CompareDev />} />
          <Route
            path="/recruiter/jobs"
            element={
              <PrivateRoute>
                <RecruiterJobs />
              </PrivateRoute>
            }
          />
          <Route
            path="/recruiter/rating"
            element={
              <PrivateRoute>
                <Rating />
              </PrivateRoute>
            }
          />
          <Route
            path="/recruiter/jobs/:id"
            element={
              <PrivateRoute>
                <JobDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/recruiter/jobs/add-new"
            element={
              <PrivateRoute>
                <AddJobPosting />
              </PrivateRoute>
            }
          />
          <Route
            path="/recruiter/premium"
            element={
              <PrivateRoute>
                <Premium />
              </PrivateRoute>
            }
          />
          <Route
            path="profile/verify-recruiter"
            element={
              <PrivateRoute>
                <VerifyRecruiter />
              </PrivateRoute>
            }
          />
          <Route
            path="/recruiter/edit-job/:id"
            element={
              <PrivateRoute>
                <EditJob />
              </PrivateRoute>
            }
          />
          <Route
            path="/recruiter/feedback"
            element={
              <PrivateRoute>
                <RecruiterFeedback />
              </PrivateRoute>
            }
          />

          {/* management */}
          <Route
            path="/management/developers"
            element={
              <PrivateRoute>
                <MgtDevelopers />
              </PrivateRoute>
            }
          />
          <Route
            path="/management/pro"
            element={
              <PrivateRoute>
                <MgtPro />
              </PrivateRoute>
            }
          />
          <Route
            path="/management/rating"
            element={
              <PrivateRoute>
                <MgtRating />
              </PrivateRoute>
            }
          />
          <Route
            path="/management/rating/:id"
            element={
              <PrivateRoute>
                <RatingDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/management/feedback"
            element={
              <PrivateRoute>
                <MgtFeedback />
              </PrivateRoute>
            }
          />
          <Route
            path="/management/feedback/:id"
            element={
              <PrivateRoute>
                <FeedbackDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/management/pro/:id"
            element={
              <PrivateRoute>
                <ProDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/management/customer-support"
            element={
              <PrivateRoute>
                <CustomerSupport />
              </PrivateRoute>
            }
          />
          <Route
            path="/management/customer-support/:id"
            element={
              <PrivateRoute>
                <CSUDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/management/customer-support/add"
            element={
              <PrivateRoute>
                <AddSupport />
              </PrivateRoute>
            }
          />
          <Route
            path="/management/schedules"
            element={
              <PrivateRoute>
                <Schedules />
              </PrivateRoute>
            }
          />
          <Route
            path="/management/schedules/:id"
            element={
              <PrivateRoute>
                <ScheduleDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/management/send-emails"
            element={
              <PrivateRoute>
                <BulkEmails />
              </PrivateRoute>
            }
          />

          <Route
            path="/management/developers/:id"
            element={
              <PrivateRoute>
                <DeveloperDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/management/recruiters/:id"
            element={
              <PrivateRoute>
                <RecruiterDetails />
              </PrivateRoute>
            }
          />

          <Route
            path="/management/recruiters"
            element={
              <PrivateRoute>
                <Recruiters />
              </PrivateRoute>
            }
          />

          {/* shared routes  */}
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <NotFound />
              </PrivateRoute>
            }
          />
          <Route path="/*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
