import React from "react";

function UploadIcon() {
  return (
    <svg
      width="66"
      height="84"
      viewBox="0 0 66 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44 25C43.4477 25 43 24.5523 43 24V2H8.1C6.61853 2 5.63173 2.00166 4.87447 2.06556C4.14174 2.1274 3.80792 2.23661 3.59621 2.3487C3.06474 2.6301 2.6301 3.06474 2.3487 3.59621C2.23661 3.80792 2.1274 4.14174 2.06556 4.87447C2.00166 5.63173 2 6.61853 2 8.1V75.9C2 77.3815 2.00166 78.3683 2.06556 79.1255C2.1274 79.8583 2.23661 80.1921 2.34871 80.4038C2.63011 80.9353 3.06474 81.3699 3.59621 81.6513C3.80792 81.7634 4.14174 81.8726 4.87447 81.9344C5.63173 81.9983 6.61853 82 8.1 82H57.9C59.3815 82 60.3683 81.9983 61.1255 81.9344C61.8583 81.8726 62.1921 81.7634 62.4038 81.6513C62.9353 81.3699 63.3699 80.9353 63.6513 80.4038C63.7634 80.1921 63.8726 79.8583 63.9344 79.1255C63.9983 78.3683 64 77.3815 64 75.9V25H44ZM66 24V75.9C66 78.7947 66 80.242 65.4188 81.3396C64.9498 82.2254 64.2254 82.9498 63.3396 83.4188C62.242 84 60.7947 84 57.9 84H8.1C5.20533 84 3.75799 84 2.66035 83.4188C1.77456 82.9498 1.05017 82.2254 0.581172 81.3396C0 80.242 0 78.7947 0 75.9V8.1C0 5.20533 0 3.75799 0.581172 2.66035C1.05017 1.77456 1.77456 1.05017 2.66035 0.581172C3.75799 0 5.20533 0 8.1 0H44L66 24ZM62.3702 23L45 4.05069V23H62.3702ZM33 70C32.4477 70 32 69.5523 32 69V39L21.8 52.6C21.4686 53.0418 20.8418 53.1314 20.4 52.8C19.9582 52.4686 19.8686 51.8418 20.2 51.4L32.2 35.4C32.3889 35.1482 32.6852 35 33 35C33.3148 35 33.6111 35.1482 33.8 35.4L45.8 51.4C46.1314 51.8418 46.0418 52.4686 45.6 52.8C45.1582 53.1314 44.5314 53.0418 44.2 52.6L34 39V69C34 69.5523 33.5523 70 33 70Z"
        fill="#F46C32"
      />
    </svg>
  );
}

export default UploadIcon;
