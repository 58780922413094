import { MdClose, MdOutlineAddAPhoto, MdOutlineClose } from "react-icons/md";
import { Formik, Form } from "formik";
import { supabase } from "helpers";
import ImageUpload from "components/to-be-cleaned/ImageUpload";
import { useAuth } from "components/auth/AuthContext";
import { generateURL } from "helpers/utils/downloadFiles";
import { uploadLogo } from "helpers/utils/uploadFiles";
import { useLockBodyScroll } from "hooks";

export function CompanyModal({
  setCompanyModal,
  company_name,
  company_email,
  phone_number,
  description,
  tagline,
  industry,
  logo_url,
  handle,
  location,
  edit,
  company_id,
  handle_id,
}) {
  const {
    user: { id: user_id },
  } = useAuth();

  useLockBodyScroll();

  const initialValues = {
    company_name: company_name ?? "",
    company_email: company_email ?? "",
    phone_number: phone_number ?? "",
    description: description ?? "",
    tagline: tagline ?? "",
    industry: industry ?? "",
    logo: null,
    logo_url: logo_url,
    handle: handle,
    location: location ?? "",
  };

  return (
    <div
      className="h-screen w-screen bg-black bg-opacity-40 fixed top-0 left-0 bottom-0 right-0 z-20 flex justify-center items-center overflow-hidden"
      onClick={(event) => {
        if (!event.target.matches(".modalContain")) {
          setCompanyModal(false);
        }
      }}
    >
      <div
        className="bg-white rounded-md w-10/12 md:w-9/12 lg:w-6/12 h-5/6 overflow-y-scroll relative modalContain"
        onClick={(event) => event.stopPropagation()}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={async ({ logo, background, ...values }, { resetForm }) => {
            try {
              if (logo) {
                const { path } = await uploadLogo(logo, user_id);
                const { publicUrl } = await generateURL(path, "logos");
                values.logo_url = publicUrl;
              }

              if (edit) {
                values.company_id = company_id;
                values.handle_id = handle_id;

                const { error } = await supabase.rpc(
                  "update_recruiter_company",
                  { details: JSON.stringify(values) }
                );
                if (error) throw error;
              } else {
                const { error } = await supabase.rpc("add_recruiter_company", {
                  details: JSON.stringify(values),
                });
                if (error) throw error;
              }
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form className="">
              <div className="border-b-[1px] border-gray-500 px-5 py-2 pb-5 flex justify-between sticky left-0 z-20 bg-white bg-opacity-80 right-0 top-0">
                <div className="flex gap-2 items-center">
                  <span
                    className="w-8 h-8 hover:bg-gray-200 flex justify-center items-center cursor-pointer rounded"
                    onClick={() => {
                      setCompanyModal(false);
                    }}
                  >
                    <MdClose size={22} />
                  </span>
                  <h2 className="font-semibold">Add Company Details</h2>
                </div>
                <button
                  className="bg-ablestate-orange text-white py-1 px-4 rounded-md text-sm"
                  type="submit"
                >
                  Save
                </button>
              </div>
              <h2 className="mx-10">Upload Logo</h2>
              <div className="mx-10 mb-5 flex items-end justify-between h-20 w-20 md:h-28 md:w-28 rounded-full">
                <div
                  className={`w-full h-full rounded-full overflow-hidden bg-cover border-2 border-white relative flex justify-center items-center bg-gray-200`}
                  style={{ backgroundImage: `url(${values?.logo_url})` }}
                >
                  <button
                    type="submit"
                    className="flex bg-gray-900 w-8 h-8 rounded-full justify-center items-center text-white opacity-50 relative mr-2 cursor-pointer"
                  >
                    <ImageUpload
                      fileRef="logo"
                      urlRef="logo_url"
                      inputStyles="absolute top-0 right-0 h-full w-full opacity-0 rounded-full"
                      setFieldValue={setFieldValue}
                    />
                    <MdOutlineAddAPhoto />
                  </button>
                  <div
                    className="flex bg-gray-900 w-8 h-8 rounded-full justify-center items-center text-white opacity-50 cursor-pointer"
                    onClick={() => {
                      setFieldValue("logo", "");
                      setFieldValue("logo_url", "");
                    }}
                  >
                    <MdOutlineClose />
                  </div>
                </div>
              </div>
              <section className="px-10 mb-5">
                <div className="relative mb-5 rounded">
                  <input
                    type="text"
                    id="company_name"
                    name="company_name"
                    className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                    placeholder=" "
                    defaultValue={values?.company_name}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="comapany_name"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                  >
                    Company Name <span className="text-red-400">*</span>
                  </label>
                </div>
                <div className="relative mb-5 rounded">
                  <input
                    type="text"
                    id="tagline"
                    name="tagline"
                    className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                    placeholder=" "
                    defaultValue={values?.tagline}
                    onChange={handleChange("tagline")}
                    maxLength={140}
                  />
                  <label
                    htmlFor="tagline"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                  >
                    Tagline <span className="text-red-400">*</span>
                  </label>
                  {values.tagline.length === 140 && (
                    <span className="text-xs text-red-500">
                      maximum character length reached
                    </span>
                  )}
                </div>
                <div className="relative mb-3 rounded">
                  <textarea
                    rows="3"
                    id="description"
                    className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] focus:outline-none focus:ring-0 peer resize-none"
                    placeholder=" "
                    defaultValue={values?.description}
                    onChange={handleChange("description")}
                  />
                  <label
                    htmlFor="description"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                  >
                    Description <span className="text-red-400">*</span>
                  </label>
                </div>
                <div className="relative mb-5 rounded">
                  <input
                    type="text"
                    id="industry"
                    name="industry"
                    className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                    placeholder=" "
                    defaultValue={values?.industry}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="tagline"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                  >
                    Industry <span className="text-red-400">*</span>
                  </label>
                </div>
                <div className="relative mb-5 rounded">
                  <input
                    type="text"
                    id="company_email"
                    name="company_email"
                    className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                    placeholder=" "
                    defaultValue={values?.company_email}
                    onChange={handleChange("company_email")}
                  />
                  <label
                    htmlFor="company_email"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                  >
                    Company Email <span className="text-red-400">*</span>
                  </label>
                </div>
                <div className="relative mb-5 rounded">
                  <input
                    type="text"
                    id="phone_number"
                    name="phone_number"
                    className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                    placeholder=" "
                    defaultValue={values?.phone_number}
                    onChange={handleChange("phone_number")}
                  />
                  <label
                    htmlFor="phone_number"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                  >
                    Phone Number <span className="text-red-400">*</span>
                  </label>
                </div>
                <div className="relative mb-5 rounded">
                  <input
                    type="text"
                    id="location"
                    name="location"
                    className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                    placeholder=" "
                    defaultValue={values?.location}
                    onChange={handleChange("location")}
                  />
                  <label
                    htmlFor="location"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                  >
                    Location <span className="text-red-400">*</span>
                  </label>
                </div>

                <div className="relative mb-10 rounded">
                  <input
                    type="text"
                    id="handle"
                    className="block rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                    placeholder=" "
                    defaultValue={values?.handle}
                    onChange={handleChange("handle")}
                  />
                  <label
                    htmlFor="handle"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                  >
                    Handle <span className="text-red-400">*</span>
                  </label>
                </div>
              </section>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
