import { Formik } from "formik";
export function EditElement({ initialValues, indexToUpdate, setEditID, editID, formInitialValues, setInitialValues }) {
  return (
    <>
      <div className="border-b-[1px] mb-5 flex justify-between items-center">
        <button className="w-full px-3 border-r-[1px] py-2">Content</button>
      </div>
      <Formik enableReinitialize initialValues={formInitialValues}>
        {({ values, handleChange, handleBlur }) => (
          <div>
            {initialValues?.form &&
            initialValues?.form[indexToUpdate] &&
            initialValues?.form[indexToUpdate].type === "section" ? (
              <div className="flex w-full flex-col px-3 py-1 lg:px-4 my-1 text-sm">
                <div className="outline-1 outline-dashed px-5 py-5 rounded-md grid grid-cols-3 gap-5 md:gap-5">
                  <div
                    className={`w-full h-5 bg-gray-200 cursor-pointer ${
                      initialValues?.form[indexToUpdate].layout.length === 1 && "border-[0.8px] border-ablestate-orange"
                    }`}
                  ></div>
                  <div
                    className={`grid grid-cols-2 gap-1 cursor-pointer ${
                      initialValues?.form[indexToUpdate].layout.length === 2 && "border-[0.8px] border-ablestate-orange"
                    }`}
                    onClick={() => {
                      console.log("clicked: ", initialValues?.form[indexToUpdate].layout);

                      // setInitialValues(prev => {
                      //   return {
                      //     prev, form
                      //   }
                      // });

                      // initialValues?.form[indexToUpdate].layout.push({
                      //   class: "",
                      //   id: 22,
                      //   label: "This is a demonstration of formBuilder running in a React project.",
                      //   type: "p",
                      // });
                    }}
                  >
                    <div className="w-full h-5 bg-gray-200"></div>
                    <div className="w-full h-5 bg-gray-200"></div>
                  </div>
                  <div
                    className={`grid grid-cols-3 gap-1 cursor-pointer ${
                      initialValues?.form[indexToUpdate].layout.length === 3 && "border-[0.8px] border-ablestate-orange"
                    }`}
                  >
                    <div className="w-full h-5 bg-gray-200"></div>
                    <div className="w-full h-5 bg-gray-200"></div>
                    <div className="w-full h-5 bg-gray-200"></div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="flex w-full flex-col px-3 py-1 lg:px-4 mb-5 text-sm">
                  <label htmlFor="label" className="mb-2">
                    Label
                  </label>
                  {initialValues?.form && values.type === "p" ? (
                    <textarea
                      type="text"
                      name="label"
                      onChange={handleChange("label")}
                      id=""
                      className="border-[1px] border-black rounded-md px-3 py-2 ring-0 focus:outline-none bg-transparent"
                      value={values.label}
                      placeholder="Enter label"
                    />
                  ) : (
                    <input
                      type="text"
                      name="label"
                      onChange={handleChange("label")}
                      id=""
                      className="border-[1px] border-black rounded-md px-3 py-2 ring-0 focus:outline-none bg-transparent"
                      value={values.label}
                      placeholder="Enter label"
                    />
                  )}
                </div>
                {initialValues?.form &&
                  initialValues.form[indexToUpdate] &&
                  initialValues.form[indexToUpdate].type === "input" && (
                    <div className="flex w-full flex-col my-3">
                      <div className="flex w-full justify-between items-center px-3 py-1 lg:px-4 my-1 text-sm">
                        <label htmlFor="required">Required *</label>
                        <input
                          type="checkbox"
                          name="required"
                          onChange={handleChange("required")}
                          id=""
                          checked={values.required}
                        />
                      </div>
                    </div>
                  )}

                <div className="mt-3 px-2">
                  <div className="grid grid-cols-2 my-2">
                    <label htmlFor="">Color</label>
                    <select
                      name="class"
                      id=""
                      className="rounded-sm text-xs"
                      value={values.class}
                      onChange={handleChange("class")}
                    >
                      <option value="">--color--</option>
                      <option value="text-black">black</option>
                      <option value="text-grey-500">grey</option>
                      <option value="text-red-500">red</option>
                      <option value="text-yellow-500">yellow</option>
                    </select>
                  </div>
                </div>
              </>
            )}

            <div className="w-full flex justify-between mb-5 px-3 text-sm cursor-pointer">
              <input
                type="button"
                onClick={async () => {
                  // setShouldUpdate((prev) => !prev);
                  setEditID(null);
                }}
                value="Cancel"
                className="border-[1px] rounded-md px-3 py-2 cursor-pointer"
              />
              <input
                // type="submit"
                type="button"
                onClick={async () => {
                  if (editID > 9 && editID < 20) {
                    const indexToUpdate2 =
                      initialValues.form &&
                      editID &&
                      initialValues.form[0].layout.findIndex((element) => element.id === editID);

                    initialValues.form[0].layout[indexToUpdate2].label = values.label;
                    initialValues.form[0].layout[indexToUpdate2].required = values.required;
                    initialValues.form[0].layout[indexToUpdate2].class = values.class;
                  } else if (editID > 19 && editID < 29) {
                    const indexToUpdate2 =
                      initialValues.form &&
                      editID &&
                      initialValues.form[2].layout.findIndex((element) => element.id === editID);

                    initialValues.form[2].layout[indexToUpdate2].label = values.label;
                    initialValues.form[2].layout[indexToUpdate2].required = values.required;
                    initialValues.form[2].layout[indexToUpdate2].class = values.class;
                  } else if (editID > 29 && editID < 39) {
                    const indexToUpdate2 =
                      initialValues.form &&
                      editID &&
                      initialValues.form[3].layout.findIndex((element) => element.id === editID);

                    initialValues.form[3].layout[indexToUpdate2].label = values.label;
                    initialValues.form[3].layout[indexToUpdate2].required = values.required;
                    initialValues.form[3].layout[indexToUpdate2].class = values.class;
                  } else if (editID > 39 && editID < 49) {
                    const indexToUpdate2 =
                      initialValues.form &&
                      editID &&
                      initialValues.form[4].layout.findIndex((element) => element.id === editID);

                    initialValues.form[4].layout[indexToUpdate2].label = values.label;
                    initialValues.form[4].layout[indexToUpdate2].required = values.required;
                    initialValues.form[4].layout[indexToUpdate2].class = values.class;
                  } else if (editID > 49 && editID < 59) {
                    const indexToUpdate2 =
                      initialValues.form &&
                      editID &&
                      initialValues.form[5].layout.findIndex((element) => element.id === editID);

                    initialValues.form[5].layout[indexToUpdate2].label = values.label;
                    initialValues.form[5].layout[indexToUpdate2].required = values.required;
                    initialValues.form[5].layout[indexToUpdate2].class = values.class;
                  } else if (editID > 59 && editID < 69) {
                    const indexToUpdate2 =
                      initialValues.form &&
                      editID &&
                      initialValues.form[6].layout.findIndex((element) => element.id === editID);

                    initialValues.form[6].layout[indexToUpdate2].label = values.label;
                    initialValues.form[6].layout[indexToUpdate2].required = values.required;
                    initialValues.form[6].layout[indexToUpdate2].class = values.class;
                  } else if (editID > 69 && editID < 79) {
                    const indexToUpdate2 =
                      initialValues.form &&
                      editID &&
                      initialValues.form[7].layout.findIndex((element) => element.id === editID);

                    initialValues.form[7].layout[indexToUpdate2].label = values.label;
                    initialValues.form[7].layout[indexToUpdate2].required = values.required;
                    initialValues.form[7].layout[indexToUpdate2].class = values.class;
                  } else if (editID > 79 && editID < 89) {
                    const indexToUpdate2 =
                      initialValues.form &&
                      editID &&
                      initialValues.form[8].layout.findIndex((element) => element.id === editID);

                    initialValues.form[8].layout[indexToUpdate2].label = values.label;
                    initialValues.form[8].layout[indexToUpdate2].required = values.required;
                    initialValues.form[8].layout[indexToUpdate2].class = values.class;
                  } else if (editID > 89 && editID < 99) {
                    const indexToUpdate2 =
                      initialValues.form &&
                      editID &&
                      initialValues.form[9].layout.findIndex((element) => element.id === editID);

                    initialValues.form[9].layout[indexToUpdate2].label = values.label;
                    initialValues.form[9].layout[indexToUpdate2].required = values.required;
                    initialValues.form[9].layout[indexToUpdate2].class = values.class;
                  } else {
                    initialValues.form[indexToUpdate].label = values.label;
                    initialValues.form[indexToUpdate].required = values.required;
                    initialValues.form[indexToUpdate].class = values.class;
                  }
                  setEditID(null);
                }}
                value="Save"
                className="border-[1px] rounded-md px-3 py-2 cursor-pointer"
              />
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}
