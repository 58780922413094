import UploadFile from "components/ui/uploader/UploadFile";
import React, { useState, useEffect } from "react";
import { MdEdit } from "react-icons/md";
import Section from "./FormFields/Section";
import { FormElement } from "./FormElement";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function Customize({ setCustom, initialValues, setInitialValues }) {
  const [editID, setEditID] = useState(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {}, []);

  const handleOnDragEnd = () => {
    console.log("hello");
  };
  return (
    <>
      <div className="flex justify-between">
        <h2 className="text-base font-semibold">
          Customize your application form
          <span className="text-sm font-light"> (beta)</span>
        </h2>
        <button className="text-sm text-ablestate-orange underline cursor-pointer" onClick={() => setCustom(false)}>
          Reset
        </button>
      </div>
      <main className="w-full grid md:grid-cols-3 gap-2 mt-6">
        <section className="w-full md:col-span-2 px-2">
          <DragDropContext onDragEnd={handleOnDragEnd} className="outline outline-red-500">
            <Droppable droppableId="characters">
              {(provided) => (
                <>
                  {initialValues &&
                    initialValues.form.map((element, index) => {
                      return (
                        <Draggable key={index} draggableId={index} index={index}>
                          {(provided) => (
                            <p ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              Hello world {index}
                            </p>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </>
              )}
            </Droppable>
          </DragDropContext>
        </section>
        <section className=" w-full col-span-1 px-3 py-2">
          <FormElement
            initialValues={initialValues}
            editID={editID}
            setEditID={setEditID}
            setShouldUpdate={setShouldUpdate}
            setInitialValues={setInitialValues}
          />
        </section>
      </main>
      <div className="text-sm flex gap-2 w-full justify-between pb-5 mt-5">
        <div></div>
        <button
          className="px-3 py-2 outline outline-1 rounded"
          type="button"
          onClick={() => {
            console.log(initialValues && initialValues.form.sort((a, b) => a.sequenceNumber - b.sequenceNumber));
            setCustom(false);
          }}
        >
          Save Form
        </button>
      </div>
    </>
  );
}

export default Customize;
