import { ErrorMessage } from "formik";

export function TextArea({ label, values, name, handleChange, handleBlur, required = false, white = false }) {
  return (
    <>
      <div className="mt-3 relative w-full flex flex-col">
        <textarea
          type="text"
          className="block px-2.5 pb-2.5 pt-4 w-full border-black text-gray-900 bg-transparent rounded appearance-none focus:outline-none peer focus:ring-0 focus:border-black border-[.9px] focus:border-[1px]"
          placeholder=" "
          name={name}
          onChange={handleChange(name)}
          onBlur={handleBlur(name)}
          value={values[name]}
        />

        <label
          htmlFor={name}
          className={`absolute text-gray-500 duration-300 transform -translate-y-4 scale-95 top-1 z-10 origin-[0] ${
            white ? "bg-white" : "bg-[#f2f2f2]"
          } px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-95 peer-focus:-translate-y-4 left-1 pointer-events-none`}
        >
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      </div>
      <ErrorMessage name={name}>
        {(msg) => (
          <div className="text-xs text-red-500 text-left w-full">
            <p>{msg}</p>
          </div>
        )}
      </ErrorMessage>
    </>
  );
}
