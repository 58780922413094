import { useRef } from "react";
import { useLockBodyScroll, useOnClickOutside } from "hooks";

export function Modal({ children, setBio }) {
  useLockBodyScroll();
  const ref = useRef();
  useOnClickOutside(ref, () => setBio(false));
  return (
    <div className="h-screen w-screen bg-black bg-opacity-40 fixed top-0 left-0 bottom-0 right-0 z-20 flex justify-center items-center overflow-hidden">
      <div
        className="bg-white rounded-md w-11/12 md:w-9/12 lg:w-6/12 h-5/6 overflow-y-scroll overflow-x-hidden relative modalContain"
        onClick={(event) => event.stopPropagation()}
        ref={ref}
      >
        {children}
      </div>
    </div>
  );
}

