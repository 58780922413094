import { supabase } from "helpers";

export const generateURL = async (path, bucket_id) => {
  try {
    const { data, error } = await supabase.storage
      .from(bucket_id)
      .getPublicUrl(path);

    if (error) throw error;
    return data;
  } catch (error) {}
};
