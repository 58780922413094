import Logo from "../../icons/Logo";
import { AiOutlineTwitter, AiOutlineInstagram, AiFillFacebook, AiFillYoutube } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useMediaQuery } from "hooks";

export function Footer() {
  const matches = useMediaQuery("(max-width:1200px)");

  return (
    <footer className="px-5 md:px-12 lmd:px-20 lg:px-36 pt-10 md:pt-12 bg-[#f7f7f7] flex flex-col items-center">
      {/*       <section className="w-full flex flex-col gap-5 md:gap-0 items-center md:items-start md:flex-row md:justify-between md:py-12">
        <div className="flex flex-col items-center md:items-start md:w-3/12 w-full">
          <Link to="/" className="flex flex-col">
            <Logo dimensions="w-[4rem] h-[4rem]" show={!matches} />
          </Link>

          <p className="text-sm">Get access to top-tire talent</p>
        </div>

        <div className="flex md:items-start md:justify-between md:w-9/12 flex-wrap gap-10 md:gap-0">
          <div className="flex flex-col gap-2 mb-2">
            <h1 className="font-semibold text-lg mb-3">Developers</h1>
            <ul className="text-sm text-[#54595f]">
              <li className="mb-3 cursor-pointer">
                <Link to="/get-started">Top Job listing</Link>
              </li>
              <li className="mb-3 cursor-pointer">
                <Link to="/get-started">Apply for Jobs</Link>
              </li>
              <li className="mb-3 cursor-pointer">
                <Link to="/login">Developer Login</Link>
              </li>
              <li className="cursor-pointer">
                <a href="https://ablestate.co/category/resources/" rel="noreferrer">
                  Resources
                </a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2 mb-2">
            <h1 className="font-semibold text-lg mb-3">Recruiters</h1>
            <ul className="text-sm text-[#54595f]">
              <li className="mb-3 cursor-pointer">
                <Link to="/recruiter/sign-up">Hire Developers</Link>
              </li>
              <li className="mb-3 cursor-pointer">
                <Link to="/recruiter/login">Recruiter Login</Link>
              </li>
              <li className="mb-3 cursor-pointer">How to Hire</li>
              <li className="mb-2 text-zinc-500 cursor-pointer">
                <a href="https://ablestate.co/category/resources/" rel="noreferrer">
                  Hiring Resources
                </a>
              </li>
            </ul>
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <h1 className="font-semibold text-lg mb-3">Address</h1>
            <ul className="text-sm text-[#54595f]">
              <li className="mb-2 text-zinc-500 cursor-pointer">
                <a href="https://ablestate.co/contact-us/">Nakayiza Road, Kansanga</a>
              </li>
              <li className="mb-2 text-zinc-500 cursor-pointer">
                <a href="https://ablestate.co/contact-us/">Kampala, Uganda</a>
              </li>
              <li className="mb-2 text-zinc-500 cursor-pointer">
                <a href="https://ablestate.co/contact-us/">WhatsApp: +256 756085187</a>
              </li>
              <li className="mb-2 text-zinc-500 cursor-pointer">
                <a href="https://ablestate.co/contact-us/">Call: +256 771 561682</a>
              </li>
              <li className="md-2 text-zinc-500 cursor-pointer">
                <a href="https://ablestate.co/contact-us/">Letters: 102523, Kampala (UG)</a>
              </li>
            </ul>
          </div>
        </div>
      </section> */}
      <section className="mt-10 mb-10 md:mt-12 w-full flex flex-col gap-2 items-center md:flex-row justify-between md:items-end border-t-[.5px] border-zinc-300 py-3">
        <p className="text-sm">&copy; 2019 - {new Date().getFullYear()}, Ablestate</p>
        <div className="flex gap-2">
          <a
            href="https://twitter.com/ablestatehq"
            target="_blank"
            rel="noreferrer"
            className="border-[0.5px] border-[#54595f] cursor-pointer rounded p-1 text-[#54595f] hover:bg-[#1d9bf0] hover:border-[#1d9bf0] hover:text-white"
            aria-label="Ablestate Twitter account"
          >
            <AiOutlineTwitter size={22} />
          </a>
          <a
            href="https://www.instagram.com/ablestatehq/"
            target="_blank"
            rel="noreferrer"
            className="border-[0.5px] border-[#54595f] cursor-pointer rounded p-1 text-[#54595f] hover:bg-[#d71640] hover:border-[#d71640] hover:text-white"
            aria-label="Ablestate Instagram account"
          >
            <AiOutlineInstagram size={22} />
          </a>
          <a
            href="https://facebook.com/ablestatehq"
            target="_blank"
            rel="noreferrer"
            className="border-[0.5px] border-[#54595f] cursor-pointer rounded p-1 text-[#54595f] hover:bg-[#1773ea] hover:border-[#1773ea] hover:text-white"
            aria-label="Ablestate Facebook account"
          >
            <AiFillFacebook size={22} />
          </a>
          <a
            href="https://www.youtube.com/@ablestate"
            target="_blank"
            rel="noreferrer"
            className="border-[0.5px] border-[#54595f] cursor-pointer rounded p-1 text-[#54595f] hover:bg-[#ff0000] hover:border-[#ff0000] hover:text-white"
            aria-label="Ablestate Youtube account"
          >
            <AiFillYoutube size={22} />
          </a>
        </div>
      </section>
    </footer>
  );
}
