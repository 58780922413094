import { MdClose } from "react-icons/md";
import { Formik, Form, ErrorMessage } from "formik";
import { developerExperienceValidationSchema } from "helpers/validators";
import { supabase } from "helpers";
import { useAuth } from "../../../auth/AuthContext";
import { useLockBodyScroll } from "hooks";
import { Input, TextArea } from "components/ui";
import { format } from "date-fns";

export function ExperienceModal({ setBio }) {
  useLockBodyScroll();
  const {
    user: { id: user_id },
  } = useAuth();
  const initialValues = {
    experience: "",
    live_url: "",
    title: "",
    end_date: "",
    start_date: "",
  };

  return (
    <div
      className="h-screen w-screen bg-black bg-opacity-40 fixed top-0 left-0 bottom-0 right-0 z-20 flex justify-center items-center overflow-hidden"
      onClick={(event) => {
        if (!event.target.matches(".modalContain")) {
          setBio(false);
        }
      }}
    >
      <div
        className="bg-white rounded-md w-11/12 md:w-9/12 lg:w-6/12 h-5/6 overflow-y-scroll relative modalContain"
        onClick={(event) => event.stopPropagation()}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={developerExperienceValidationSchema}
          onSubmit={async ({ title, experience, live_url, start_date, end_date }, { resetForm }) => {
            const details = {
              title,
              experience,
              live_url,
              start_date,
              end_date,
              developer_id: user_id,
              is_current_experience: false,
            };
            try {
              const { error } = await supabase.rpc("add_user_experience", {
                details: JSON.stringify(details),
              });
              if (error) throw error;
            } catch (error) {
              console.log(error);
            } finally {
              setBio(false);
              return resetForm({ values: initialValues });
            }
          }}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form className="">
              <div className="border-b-[0.8px] border-gray-500 px-3 md:px-5 py-2 mb-1 flex justify-between sticky left-0 z-20 bg-white bg-opacity-80 right-0 top-0">
                <div className="flex gap-2 items-center">
                  <span
                    className="w-8 h-8 hover:bg-gray-200 flex justify-center items-center cursor-pointer rounded"
                    onClick={() => {
                      setBio(false);
                    }}
                  >
                    <MdClose size={22} />
                  </span>
                  <h2 className="font-semibold">Add Experience</h2>
                </div>
                <button className="bg-ablestate-orange text-white py-1 px-4 rounded-md text-sm" type="submit">
                  Save
                </button>
              </div>
              <div className="px-5 md:px-10 my-2 py-2">
                <Input
                  label="Title *"
                  values={values}
                  name="title"
                  type="text"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
              <div className="px-5 md:px-10 my-2 py-2">
                <TextArea
                  label="Description"
                  name="experience"
                  values={values}
                  white={true}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={true}
                />
              </div>
              <div className="px-5 md:px-10 my-2 py-2">
                <Input
                  label="Link"
                  values={values}
                  name="live_url"
                  type="text"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
              <div className="">
                <p className="px-5 md:px-10">Period</p>
                <div className="flex flex-col md:flex-row">
                  <div className="px-5 md:px-10 py-2 w-full">
                    <p className="text-xs">Start Date</p>
                    <input
                      type="date"
                      id="start_date"
                      name="start_date"
                      value={values.start_date ? format(new Date(values.start_date), "yyyy-MM-dd") : ""}
                      className="block rounded px-2.5 py-2 w-full text-sm text-gray-900 border-[0.8px]"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="start_date">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="px-5 md:px-10 py-2 w-full">
                    <p className="text-xs">End Date</p>
                    <input
                      type="date"
                      id="end_date"
                      name="end_date"
                      value={values.end_date ? format(new Date(values.end_date), "yyyy-MM-dd") : ""}
                      className="block rounded px-2.5 py-2 w-full text-sm text-gray-900 border-[0.8px]"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="end_date">
                      {(msg) => (
                        <div className="text-xs text-red-500 text-left w-full">
                          <p>{msg}</p>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
