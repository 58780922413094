export const programmingLanguages = [
  "C",
  "C#",
  "C++",
  "Go",
  "Java",
  "Javascript",
  "Php",
  "Python",
  "Rust",
  "Typescript",
  "Objective-C",
];
