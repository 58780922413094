import { useState, useEffect, useRef } from "react";
import { startOfToday, format } from "date-fns";
import { useFormikContext } from "formik";
import CalendarForm2 from "./CalendarFrom2";
import CalendarEnd from "./CalendarEnd";

function DateRangePicker({ reference, required }) {
  let today = startOfToday();
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const datePickerRef = useRef(null);
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    function handler(event) {
      if (!datePickerRef.current?.contains(event.target)) {
        setShow(false);
      }
    }

    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  return (
    <div
      className={`flex flex-col items-center w-full relative`}
      ref={datePickerRef}
    >
      <input
        type="text"
        id="recruitment_period"
        className={`rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] focus:outline-none focus:ring-0 peer  ${
          show ? "border-[#f36c32]" : ""
        }`}
        placeholder=" "
        defaultValue={values.recruitment_period}
        name="recruitment_period"
        onFocus={() => {
          setShow(true);
          if (!values.recruitment_period) {
            setFieldValue("recruitment_period", "dd/mm/yyyy - dd/mm/yyyy");
          }
        }}
      />
      <label
        htmlFor="recruitment_period"
        className={`absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4 ${
          show ? "" : ""
        }`}
      >
        Recruitment Period <span className="text-red-400">*</span>
      </label>
      {show && (
        <div className="bg-white px-5 py-2 absolute top-10 right-0 z-40 shadow-md border-[0.9px] border-gray-500 rounded-md flex flex-col gap-3 md:gap-5">
          <div className="flex bg-white rounded min-h-fit gap-5 p-2 w-fit mb-2 justify-between ">
            <CalendarForm2
              today={today}
              endDate={endDate ? endDate : today}
              startDate={startDate ? startDate : today}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              from={true}
            />
            <div className="h-11/12 w-[0.8px] bg-gray-300"></div>
            <CalendarEnd
              today={today}
              endDate={endDate ? endDate : today}
              startDate={startDate ? startDate : today}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              from={false}
            />
          </div>
          <div className="flex justify-end gap-2 items-center">
            <span className="text-sm">
              {values?.recruitment_period !== "dd/mm/yyyy - dd/mm/yyyy"
                ? `${format(startDate, "dd/MM/yyyy")} - ${format(
                    endDate,
                    "dd/MM/yyyy"
                  )}`
                : ""}
            </span>

            <button
              className={`rounded outline outline-1 px-2 py-2 bg-red-500 text-white text-xs`}
              type="button"
              onClick={() => {
                setShow(false);
              }}
            >
              cancel
            </button>
            <button
              className={`rounded outline outline-1 px-2 py-2 bg-black text-white text-xs`}
              type="button"
              onClick={() => {
                // document.getElementById(reference).innerText = `${format(startDate, 'dd/MM/yyyy')} - ${format(endDate, 'dd/MM/yyyy')}`
                setFieldValue(
                  "recruitment_period",
                  `${format(startDate, "dd/MM/yyyy")} - ${format(
                    endDate,
                    "dd/MM/yyyy"
                  )}`
                );
                setShow(false);
              }}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default DateRangePicker;
