import { supabase } from "../supabase";
import {URLS} from "../constants";

export const signInWithGoogle = async () => {
  const { error } = await supabase.auth.signInWithOAuth({
    provider: "google",
    options: {
      redirectTo: `${URLS.ABLESTATE}/profile`,
    },
  });

  if (error) throw error;
  if (!error) {
    return true;
  }
};

export const signInWithGithub = async () => {
  const { error } = await supabase.auth.signInWithOAuth({
    provider: "github",
    options: {
      redirectTo: `${URLS.ABLESTATE}/profile`,
    },
  });

  if (error) throw error;
  if (!error) {
    return true;
  }
};

export const signInWithLinkedIn = async () => {
  const { error } = await supabase.auth.signInWithOAuth({
    provider: "linkedin",
    options: {
      redirectTo: `${URLS.ABLESTATE}/profile`,
    },
  });

  if (error) throw error;
  if (!error) {
    return true;
  }
};

export const signInWithGitLab = async () => {
  const { error } = await supabase.auth.signInWithOAuth({
    provider: "gitlab",
    options: {
      redirectTo: `${URLS.ABLESTATE}/profile`,
    },
  });

  if (error) throw error;
  if (!error) {
    return true;
  }
};

export const signInWithEmail = async ({ email, password }) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  if (error) throw error;
  return data;
};

export const signUpDeveloperWithEmail = async ({
  email,
  password,
  fullname,
  user_role,
}) => {
  const { error, data } = await supabase.auth.signUp({
    email,
    password,
    options: {
      data: {
        fullname,
        user_role,
      },
    },
  });
  return data;
};

export const signUpRecruiterWithEmail = async ({
  email,
  password,
  fullname,
  user_role,
  company_name,
  location,
  phone_number,
}) => {
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
    options: {
      data: {
        fullname,
        email,
        user_role,
        company_name,
        location,
        phone_number,
      },
    },
  });

  if (error) throw error;
  return data;
};

export const getUserRole = async (user_id) => {
  const {
    data: [
      {
        roles: { role_name },
      },
    ],
  } = await supabase
    .from("user_roles")
    .select(
      `
      roles (
        role_name
      )
    `
    )
    .eq("user_id", user_id);
  return role_name;
};

export const signUpOwnerWithEmail = async ({
  fullname,
  email,
  user_role,
  password,
}) => {
  const { error, data } = await supabase.auth.signUp({
    email,
    password,
    options: {
      data: {
        fullname,
        email,
        user_role,
      },
    },
  });

  if (error) throw error;
  return data;
};
