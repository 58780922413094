import { IoClose } from "react-icons/io5";
import { MdAddCircle } from "react-icons/md";
import DateRangePicker from "components/ui/Calendar/DateRangePicker";
import { Formik, Form, ErrorMessage } from "formik";
import { addJobPostPart1Schema } from "helpers";

export function Part1({ show, setShow, initialValues, setInitialValues, setOpenTab }) {
  return (
    <Formik
      enableReinitialize
      validationSchema={addJobPostPart1Schema}
      initialValues={initialValues}
      onSubmit={async (values, resetForm) => {
        // console.log("hello world: ", values);
        setInitialValues(values);
        setOpenTab(1);
        // addJob(values, resetForm);
      }}
    >
      {({ values, setFieldValue, handleChange }) => (
        <Form>
          <section className="grid md:grid-cols-2 gap-x-20 gap-y-5 mt-5 my-5">
            <div className="">
              <div className="relative rounded w-full">
                <input
                  type="text"
                  id="job_title"
                  className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] focus:outline-none focus:ring-0 peer"
                  placeholder=" "
                  value={values.job_title}
                  onChange={handleChange}
                  name="job_title"
                />
                <label
                  htmlFor="job_title"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                >
                  Job Title <span className="text-red-400">*</span>
                </label>
              </div>
              <ErrorMessage name="job_title">
                {(msg) => (
                  <div className="text-xs text-red-500 text-left w-full">
                    <p className="error-messages">{msg}</p>
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div className="">
              <div className="relative rounded w-full">
                <select
                  defaultValue={values.job_type}
                  onChange={handleChange}
                  name="job_type"
                  id=""
                  className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] focus:outline-none focus:ring-0 peer"
                >
                  <option value="">Select Job Type</option>
                  <option value="full_time">Full Time</option>
                  <option value="part_time">Part Time</option>
                  <option value="freelance">Freelance</option>
                  <option value="internship">Internship</option>
                  <option value="apprenticeship">Apprenticeship</option>
                </select>
                <label
                  htmlFor="job_type"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                >
                  Job Type <span className="text-red-400">*</span>
                </label>
              </div>
              <ErrorMessage name="job_type">
                {(msg) => (
                  <div className="text-xs text-red-500 text-left w-full">
                    <p className="error-messages">{msg}</p>
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div className="">
              <div className="relative rounded w-full">
                <input
                  type="text"
                  id="head_count"
                  className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] focus:outline-none focus:ring-0 peer"
                  placeholder=" "
                  defaultValue={values.head_count}
                  onChange={handleChange}
                  name="head_count"
                />
                <label
                  htmlFor="head_count"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                >
                  Head Count <span className="text-red-400">*</span>
                </label>
              </div>
              <ErrorMessage name="head_count">
                {(msg) => (
                  <div className="text-xs text-red-500 text-left w-full">
                    <p className="error-messages">{msg}</p>
                  </div>
                )}
              </ErrorMessage>
            </div>

            <div className="relative rounded w-full">
              <DateRangePicker />
            </div>
            <div className="relative rounded w-full">
              <input
                type="text"
                id="salary_expectation"
                className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] focus:outline-none focus:ring-0 peer"
                placeholder=" "
                defaultValue={values.salary_expectation}
                onChange={handleChange}
                name="salary_expectation"
              />
              <label
                htmlFor="salary_expectation"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
              >
                Salary Expectation <span className="text-red-400">*</span>
              </label>
            </div>
            <div className="relative rounded w-full">
              <select
                defaultValue={values.experience_in_years}
                onChange={handleChange}
                name="experience_in_years"
                id=""
                className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] focus:outline-none focus:ring-0 peer"
              >
                <option value="">Select Experience</option>
                <option value="beginner">{"<1 years"}</option>
                <option value="beginner">{"1 - 2 years"}</option>
                <option value="beginner">{"2 - 3 years"}</option>
                <option value="beginner">{"3 - 5 years"}</option>
                <option value="beginner">{"5 - 10 years"}</option>
                <option value="beginner">{"10 - 15 years"}</option>
                <option value="beginner">{">15 years"}</option>
              </select>
              <label
                htmlFor="experience_in_years"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
              >
                Experience in years <span className="text-red-400">*</span>
              </label>
            </div>
            <div className="relative rounded w-full">
              <input
                type="text"
                id="location"
                className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] focus:outline-none focus:ring-0 peer"
                placeholder=" "
                defaultValue={values.location}
                onChange={handleChange}
                name="location"
              />
              <label
                htmlFor="location"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
              >
                Location <span className="text-red-400">*</span>
              </label>
            </div>
            <div className="relative rounded w-full">
              <input
                type="text"
                id="developer_location"
                className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] focus:outline-none focus:ring-0 peer"
                placeholder=" "
                defaultValue={values.developer_location}
                onChange={handleChange}
                name="developer_location"
              />
              <label
                htmlFor="developer_location"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
              >
                Developer's Location
              </label>
            </div>
            <div className="relative rounded w-full">
              <input
                type="text"
                id="industry"
                className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] focus:outline-none focus:ring-0 peer"
                placeholder=" "
                defaultValue={values.industry}
                onChange={handleChange}
                name="industry"
              />
              <label
                htmlFor="industry"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
              >
                Industry <span className="text-red-400">*</span>
              </label>
            </div>
          </section>
          <section className="mb-10">
            <h3 className="mb-3">Skill Set</h3>
            {show ? (
              <div
                id="skill_set"
                tabIndex={0}
                className="rounded w-full focus:border-[#f36c32] text-gray-900 border-[.9px] text-lg border-1 border-gray-500 min-h-12 h-fit flex gap-2 flex-wrap px-2 pb-2 pt-6 mb-5 relative focus-within:text-sm group focus-within:border-ablestate-orange"
                onKeyDown={(event) => {
                  if (event.key === "Enter" || event.key === ",") {
                    setFieldValue("skill_set", [...values.skill_set, values.skill]);
                  }
                }}
              >
                {values.skill_set &&
                  values.skill_set.length > 0 &&
                  values.skill_set.map((skill, index) => {
                    return (
                      <div
                        className="flex cursor-pointer rounded-full outline outline-[.9px] pl-4 pr-2 py-1 text-xs md:text-sm gap-2"
                        key={index}
                      >
                        {skill}
                        <div
                          className="flex justify-center items-center rounded-full w-5 h-5 bg-ablestate-orange text-white"
                          onClick={() => {
                            values.skill_set.splice(index, 1);
                            setFieldValue("skill_set", values.skill_set);
                          }}
                        >
                          <IoClose />
                        </div>
                      </div>
                    );
                  })}
                <label
                  htmlFor="skills"
                  className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100"
                >
                  Skills <span className="text-red-400">*</span>
                </label>
                <input
                  className="h-12 w-full rounded ring-0 focus:ring-0 outline-0 focus:outline-0  focus:text-sm px-2"
                  onChange={(event) => {
                    if (event.target.value === ",") {
                      event.target.value = "";
                    } else {
                      setFieldValue("skill", event.target.value);
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" || event.key === ",") {
                      event.target.value = "";
                    }
                  }}
                />
              </div>
            ) : (
              <button
                className="text-xs cursor-pointer flex justify-start gap-2 items-center mb-5"
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  setShow(true);
                }}
              >
                <MdAddCircle color={"#F46C32"} size={25} /> Add a new skill
              </button>
            )}
            <div className="relative mb-5 rounded">
              <textarea
                rows="3"
                id="job_description"
                className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] focus:outline-none focus:ring-0 peer resize-none"
                placeholder=" "
                defaultValue={values.job_description}
                onChange={handleChange("job_description")}
              />
              <label
                htmlFor="job_description"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
              >
                Job Description <span className="text-red-400">*</span>
              </label>
            </div>
          </section>
          <div className="text-sm flex gap-2 w-full justify-between pb-5">
            <div></div>
            <button className="px-3 py-2 outline outline-1 rounded" type="submit">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

Part1.propTypes = {};
