import { useOnClickOutside, useLockBodyScroll } from "hooks";
import { useRef } from "react";

export function TextConfirmModal({ name, userID, handleDelete, setPopUp, title }) {
  const ref = useRef();
  useLockBodyScroll();
  useOnClickOutside(ref, () => setPopUp(false));
  return (
    <div
      onClick={(event) => event.stopPropagation()}
      className={`bg-black z-20 bg-opacity-40 w-screen h-screen fixed top-0 left-0 right-0 flex justify-center items-center`}
    >
      <main
        className="relative bg-white dark:bg-dark-bg max-h-screen overflow-auto dark:text-secondary-text py-2 px-4 md:p-10  rounded-sm m-2 sm:mb-5 shadow-md top-50 z-20"
        ref={ref}
      >
        <h1 className="text-center font-bold text-lg my-5">Delete {title}</h1>
        <p>
          Are you sure you want to delete <b>{name}</b>?
        </p>
        <div className="flex justify-between mt-5">
          <button
            className="border-[1px] border-[#1D1F20] bg-[#1D1F20] text-white py-2 px-4 hover:bg-[#1D1F20] hover:text-white flex items-center gap-2 rounded-md"
            onClick={() => setPopUp(false)}
          >
            No, Cancel
          </button>
          <button
            className="border-[1px] border-[#1D1F20] text-[#1D1F20] py-2 px-4 hover:bg-[#1D1F20] hover:text-white flex items-center gap-2 rounded-md"
            onClick={(event) => handleDelete(event, userID)}
          >
            Yes, Delete
          </button>
        </div>
      </main>
    </div>
  );
}
