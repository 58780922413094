import * as Yup from "yup";
import { supabase } from "./supabase";

const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

export const signInWithEmailValidationSchema = Yup.object().shape({
  email: Yup.string().required("Email Address is required.").email("Invalid Email."),
  password: Yup.string().min(8, "Should be at least 8 characters.").required("Password Required."),
});

export const addCustomerValidationSchema = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "must follow example@example.com"),
  fullName: Yup.string().required("Full Name is required").min(3, "Must be greater than 3 characters."),
});

export const renewPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().required("Email Address is required.").email("Invalid Email."),
});

export const resetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string().min(8, "Should be at least 8 characters.").required("Password Required."),
  confirm_password: Yup.string().min(8, "Should be at least 8 characters.").required("Password Required."),
});

export const getStartedValidationSchema = Yup.object().shape({
  fullname: Yup.string().required("Full Name required."),
  email: Yup.string().required("Email required."),
  password: Yup.string().min(8, "Should be at least 8 characters.").required("Password required."),
});

export const recruiterSignUpValidationSchema = Yup.object().shape({
  company_name: Yup.string().required("Company name required."),
  fullname: Yup.string().required("Fullname is required."),
  email: Yup.string().required("Email is required."),
  phone_number: Yup.string().required("Phone number is required."),
  password: Yup.string().min(8, "Should be at least 8 characters.").required("Password required."),
});

export const developerExperienceValidationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  experience: Yup.string().required("Experience is required"),
});

export const proTestValidationSchema = Yup.object().shape({
  date_of_assessment: Yup.string().test(
    "Schedule date requirement",
    "Select the date and time of assessment",
    function (dateOfAssessment) {
      if (!this.parent.time_slot_id && !dateOfAssessment) {
        return false;
      } else if (dateOfAssessment && !this.parent.time_slot_id) {
        this.createError("time_slot_id", "Choose the time of the day you're willing to take the assessment");
        return true;
      } else {
        return true;
      }
    },
  ),
  time_slot_id: Yup.string().test(
    "Schedule time requirement",
    "Choose the time of the day you're willing to take the assessment",
    function (slotID) {
      if (this.parent.date_of_assessment && !slotID) {
        return false;
      } else if (!slotID && this.parent.date_of_assessment) {
        return false;
      } else {
        return true;
      }
    },
  ),
});

export const developerEducationSchema = Yup.object().shape({
  degree: Yup.string().required("Enter the education details."),
  description: Yup.string().required("Enter the description."),
  user_school: Yup.string().required("Enter the Institution."),
  start_date: Yup.string().required("Start date is required."),
  end_date: Yup.string().required("End date is required."),
});

export const emailValidationSchema = Yup.object().shape({
  subject: Yup.string().required("Subject can't be empty"),
});

export const feedbackValidationSchema = Yup.object().shape({
  accomplished: Yup.string().required("Accomplished is required"),
});

export const skillValidationSchema = Yup.object().shape({
  skill_name: Yup.string().required("Skill is required"),
});

export const developerBioValidationSchema = Yup.object().shape({
  fullname: Yup.string().required("Fullname is required"),
  tagline: Yup.string().required("Tagline is required"),
  handle: Yup.string()
    .required("Handle is required")
    .test("Handle existence", "Handle already taken", async function (new_handle) {
      if (new_handle && new_handle !== this.parent.current_handle) {
        const { data } = await supabase
          .from("handle_pool")
          .select("handle")
          .eq("handle", new_handle)
          .neq("id", this.parent.handle_id)
          .single();

        return !data;
      }

      return true;
    }),
  primary_programming_language: Yup.string().required("Primary Programmming Language is required"),
  phone: Yup.string().matches(phoneRegex, "Invalid phone number"),
  gender: Yup.string(),
  country: Yup.string().required("Country is required"),
  human_language: Yup.string(),
  bio: Yup.string().required("Bio is required"),
  github: Yup.string().required("Github username is required"),
  handle_id: Yup.string(),
  current_handle: Yup.string(),
});

export const addJobPostPart1Schema = Yup.object().shape({
  job_title: Yup.string().required("Job title is required"),
  job_type: Yup.string().required("Job type is required"),
});
