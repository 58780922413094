import { Formik, Form, ErrorMessage } from "formik";
import { supabase } from "helpers";
import { developerEducationSchema } from "helpers/validators";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { MdOutlineModeEdit, MdOutlineArrowBackIos, MdOutlineDeleteOutline, MdClose } from "react-icons/md";
import { useState } from "react";
import { Modal } from "../Modal";
import PropTypes from "prop-types";

export function EditEducationModal({ setBio, data }) {
  const [edit, setEdit] = useState(null);

  const handleDelete = async (id) => {};

  return (
    <Modal setBio={setBio}>
      <Formik
        initialValues={{
          degree: edit?.degree ?? "",
          description: edit?.description ?? "",
          user_school: edit?.user_school ?? "",
          start_date: edit?.start_date ?? "",
          end_date: edit?.end_date ?? "",
        }}
        enableReinitialize
        validationSchema={developerEducationSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            const { data, error } = await supabase.from("user_education").update(values).eq("id", edit.id);

            if (error) {
              toast.error(`${error.message}`, { position: "top-center" });
            }

            if (data) {
              toast.success(`Education updated successful.`, {
                position: "top-center",
                theme: "colored",
              });
            }
          } catch (error) {
            toast.error(`${error.message}`, { position: "top-center" });
          } finally {
            resetForm();
            setBio(false);
          }
          resetForm();
        }}
      >
        {({ values, handleChange, handleBlur }) => (
          <Form className="py-5">
            <div className="border-b-[1px] border-gray-400 px-3 md:px-5 py-2 mb-1 flex justify-between sticky left-0 z-20 bg-white bg-opacity-80 right-0 top-0">
              {edit ? (
                <div className="flex gap-2 items-center">
                  <span
                    className="w-8 h-8 hover:bg-gray-200 flex justify-center items-center cursor-pointer rounded"
                    onClick={() => {
                      setEdit(false);
                    }}
                  >
                    <MdOutlineArrowBackIos size={22} />
                  </span>
                  <h2 className="font-semibold">{edit.degree}</h2>
                </div>
              ) : (
                <div className="flex gap-2 items-center">
                  <span
                    className="w-8 h-8 hover:bg-gray-200 flex justify-center items-center cursor-pointer rounded"
                    onClick={() => {
                      setBio(false);
                    }}
                  >
                    <MdClose size={22} />
                  </span>
                  <h2 className="font-semibold">Edit Education</h2>
                </div>
              )}
              {edit && (
                <button className="bg-ablestate-orange text-white py-1 px-4 rounded-md text-sm" type="submit">
                  Save
                </button>
              )}
            </div>
            {edit && (
              <div>
                <div className="px-5 md:px-10 my-2 py-2">
                  <p>Education</p>
                  <input
                    type="text"
                    id="degree"
                    name="degree"
                    value={values.degree}
                    className="block rounded px-2.5 py-2 w-full text-sm text-gray-900 outline outline-[0.8px]"
                    placeholder="Degree/ Certification name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage name="degree">
                    {(msg) => (
                      <div className="text-xs text-red-500 text-left w-full">
                        <p>{msg}</p>
                      </div>
                    )}
                  </ErrorMessage>
                </div>
                <div className="px-5 md:px-10 my-2 py-2">
                  <p>Educational Institution</p>
                  <input
                    type="text"
                    id="user_school"
                    name="user_school"
                    value={values.user_school}
                    className="block rounded px-2.5 py-2 w-full text-sm text-gray-900 outline outline-[0.8px]"
                    placeholder="Enter institution"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage name="user_school">
                    {(msg) => (
                      <div className="text-xs text-red-500 text-left w-full">
                        <p>{msg}</p>
                      </div>
                    )}
                  </ErrorMessage>
                </div>
                <div className="px-5 md:px-10 my-2 py-2">
                  <p>Description</p>
                  <textarea
                    type="text"
                    id="description"
                    name="description"
                    defaultValue={values.description}
                    className="block rounded px-2.5 py-2 w-full text-sm text-gray-900 outline outline-[0.8px]"
                    placeholder="Description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage name="description">
                    {(msg) => (
                      <div className="text-xs text-red-500 text-left w-full">
                        <p>{msg}</p>
                      </div>
                    )}
                  </ErrorMessage>
                </div>
                <div className="">
                  <p className="px-5 md:px-10">Year of Study</p>
                  <div className="flex flex-col md:flex-row">
                    <div className="px-5 md:px-10 py-2 w-full">
                      <p>Start Date</p>
                      <input
                        type="date"
                        id="start_date"
                        name="start_date"
                        value={values.start_date ? format(new Date(values.start_date), "yyyy-MM-dd") : ""}
                        className="block rounded px-2.5 py-2 w-full text-sm text-gray-900 outline outline-[0.8px]"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage name="start_date">
                        {(msg) => (
                          <div className="text-xs text-red-500 text-left w-full">
                            <p>{msg}</p>
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="px-5 md:px-10 py-2 w-full">
                      <p>End Date</p>
                      <input
                        type="date"
                        id="end_date"
                        name="end_date"
                        value={values.end_date ? format(new Date(values.end_date), "yyyy-MM-dd") : ""}
                        className="block rounded px-2.5 py-2 w-full text-sm text-gray-900 outline outline-[0.8px]"
                        placeholder="end_date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage name="end_date">
                        {(msg) => (
                          <div className="text-xs text-red-500 text-left w-full">
                            <p>{msg}</p>
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
      {!edit && (
        <div className="px-3 md:px-8 py-5">
          {data &&
            data?.length > 0 &&
            data.map((educ, index) => (
              <div className="border-b-[1px] py-3" key={index}>
                <div className="flex justify-between">
                  <h2 className="font-bold">{educ.degree}</h2>
                  <div className="flex gap-1">
                    <MdOutlineModeEdit size={20} className="cursor-pointer" onClick={() => setEdit(educ)} />
                    <MdOutlineDeleteOutline
                      size={20}
                      className="cursor-pointer"
                      onClick={() => {
                        handleDelete(educ.id);
                      }}
                    />
                  </div>
                </div>
                <p className="text-sm text-gray-500 line-clamp-3">{educ.description}</p>
                <p className="text-sm text-gray-500">{educ.user_school}</p>
                <div className="flex gap-1 text-gray-500 items-center">
                  <p className="text-xs">{format(new Date(educ.start_date), "MM/yyyy")}</p> -{" "}
                  <p className="text-xs">{format(new Date(educ.start_date), "MM/yyyy")}</p>
                </div>
              </div>
            ))}
        </div>
      )}
    </Modal>
  );
}

EditEducationModal.propTypes = {
  data: PropTypes.array,
};
