import { supabase } from "helpers";

/**
 * @function
 * @name uploadAvatar
 * @param {string} file the file that has been selected in the form to be uploaded.
 * @param {string} storage_bucket the storage bucket where you want to store the image.
 * @returns {Object} An object containing either an error or some data.
 */

export const uploadAvatar = async (file, user_id) => {
  const [, fileExtension] = file.name.split(".");

  try {
    const { error, data } = await supabase.storage
      .from("avatars")
      .upload(`${user_id}/avatar.${fileExtension}`, file, {
        cacheControl: 3600,
        upsert: true,
      });

    if (error) {
      // throw error
      console.log("Upload Error: ", error);
    } else {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const uploadBackground = async (file, user_id) => {
  const [, fileExtension] = file.name.split(".");

  try {
    const { error, data } = await supabase.storage
      .from("backgrounds")
      .upload(`${user_id}/background.${fileExtension}`, file, {
        cacheControl: 3600,
        upsert: true,
      });

    if (error) {
      throw error;
    } else {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const uploadLogo = async (file, user_id) => {
  const [, fileExtension] = file.name.split(".");

  try {
    const { error, data } = await supabase.storage
      .from("logos")
      .upload(`${user_id}/logo.${fileExtension}`, file, {
        cacheControl: 3600,
        upsert: true,
      });

    if (error) {
      throw error;
    } else {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};
