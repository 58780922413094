import { IoMdPerson } from "react-icons/io";
function DeveloperDetailsLoader() {
  return (
    <>
      <div className="w-full">
        <div className="bg-gray-200 animate-pulse w-full h-40 md:h-60 p-2 relative">
          <div className="absolute -bottom-12 md:-bottom-16 mx-2 flex items-end justify-between w-11/12">
            <div className="flex items-end">
              <div className="overflow-hidden w-20 h-20 md:w-28 md:h-28 rounded-full border-2 md:border-4 border-white bg-gray-100 flex justify-center items-center">
                <IoMdPerson size={64} className="text-gray-200 animate-pulse" />
              </div>
              <div className="ml-2 md:ml-5">
                <div className="bg-gray-100 w-64 rounded-md h-6 animate-pulse mb-1"></div>
                <div className="bg-gray-100 w-10/12 rounded-md h-3 mb-2 animate-pulse"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-20 md:mt-24">
        <div className="text-gray-500 line-clamp-3">
          <div className="bg-gray-100 w-full mb-2 rounded-md h-3 animate-pulse mt-4"></div>
          <div className="bg-gray-100 w-full mb-2 rounded-md h-3 animate-pulse"></div>
          <div className="bg-gray-100 w-10/12 rounded-md h-3 mb-2 animate-pulse"></div>
        </div>
      </div>
      <div className="my-5 md:text-left flex">
        {[{}, {}, {}].map((skill, index) => (
          <div
            key={index}
            className="cursor-pointer rounded-full mx-1 border-[1px] border-gray-100 px-3 py-2 flex justify-center items-center gap-1"
          >
            <div className="bg-gray-100 w-6 h-6 rounded-full animate-pulse"></div>
            <div className="bg-gray-100 w-20 rounded-md h-4 animate-pulse"></div>
          </div>
        ))}
      </div>
    </>
  );
}

export default DeveloperDetailsLoader