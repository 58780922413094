import PropTypes from "prop-types";

export function SignUpButton({ type, icon, signUp }) {
  return (
    <button
      className="w-full rounded  my-1 p-2 border-[0.9px] border-black transform transition hover:scale-[101%] duration-100 ease-in-out flex justify-center items-center gap-3"
      onClick={async (event) => {
        event.preventDefault();
        try {
          const response = await signUp();
        } catch (error) {}
      }}
      type="button"
    >
      {icon}
      <p>{type}</p>
    </button>
  );
}

SignUpButton.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  signUp: PropTypes.func,
};
