import { MdAdd } from "react-icons/md";
import { IoMdPerson } from "react-icons/io";
import { Link } from "react-router-dom";

export function MoreDeveloperCard() {
  return (
    <div className="w-full h-full">
      <div className="px-4 py-4 bg-ablestate-gunmetal-500 overflow-hidden rounded shadow-lg flex flex-col relative overflow-y-hidden h-full">
        <div className="bg-ablestate-gunmetal-500 bg-opacity-90 text-white w-full h-full absolute left-0 top-0 p-4 flex justify-center items-center flex-col">
          <Link to={"/recruiter/premium"} aria-label="See more developers">
            <span className="bg-ablestate-gunmetal-300 w-10 h-10 flex justify-center items-center rounded-full cursor-pointer">
              <MdAdd size={25} />
            </span>
          </Link>
          <p className="text-gray-400">see more</p>
        </div>
        <div className="flex items-start mb-6 gap-5">
          <div className="w-[40px] h-[40px] rounded-full bg-gray-100 flex justify-center items-center">
            <IoMdPerson size={25} className="text-gray-300" />
          </div>
          <header className="text-white">
            <h1 className="">John Doe</h1>
            <p className="mb-2 text-sm">Frontend Developer </p>
            <div className="flex flex-wrap gap-1">
              <span className="bg-ablestate-gunmetal-300 px-2 py-1 rounded text-xs">
                React
              </span>
              <span className="bg-ablestate-gunmetal-300 px-2 py-1 rounded text-xs">
                UI/UX
              </span>
            </div>
          </header>
        </div>
        <button className="w-full  bg-ablestate-orange hover:bg-ablestate-coral rounded text-white p-1">
          <p className="text-sm">View Profile</p>
        </button>
      </div>
    </div>
  );
}
