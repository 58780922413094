import Default from "components/ui/ApplicationForm/Default";
import Customize from "components/ui/ApplicationForm/Customize";
import { useState } from "react";
import { Formik, Form } from "formik";
import { parse } from "date-fns";
import { toast } from "react-toastify";
import { supabase } from "helpers";

export function Part3({ initialValues, setInitialValues, setOpenTab }) {
  const [custom, setCustom] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const addJob = async (values, resetForm) => {
    setSubmitting(true);
    const toastID = toast.loading("Please wait. Job posting is being submitted.");
    const [open_date, close_date] = values.recruitment_period.split("-");
    values.open_date = parse(open_date.trim(), "dd/MM/yyyy", new Date()).toISOString();
    values.close_date = parse(close_date.trim(), "dd/MM/yyyy", new Date()).toISOString();

    try {
      const { error } = await supabase.rpc("add_job_posting", {
        details: values,
      });
      if (error) {
        throw error;
      } else {
        toast.update(toastID, {
          render: `Success! Job posting submitted.`,
          type: toast.TYPE.SUCCESS,
          isLoading: false,
          autoClose: 5000,
        });
        setSubmitting(false);
      }
    } catch (error) {
      toast.update(toastID, {
        render: `${error?.message ?? "Submission failed. Try again later."}`,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      setSubmitting(false);
    }

    resetForm({
      page: 1,
      job_title: "",
      skill_set: [],
      job_description: "",
      skill: "",
      stages: [
        { stage_name: "", stage_description: "", vendor: "", type: "" },
        { stage_name: "", stage_description: "", vendor: "", type: "" },
      ],
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, resetForm) => {
        setInitialValues(values);
        addJob(values, resetForm);
      }}
    >
      {({ values, handleChange }) => (
        <Form>
          {custom ? (
            <Customize setCustom={setCustom} initialValues={initialValues} setInitialValues={setInitialValues} />
          ) : (
            <Default setCustom={setCustom} initialValues={initialValues} setInitialValues={setInitialValues} />
          )}
          <div className="text-sm flex gap-2 w-full justify-between pb-5 mt-5">
            <button
              className="px-3 py-2 outline outline-1 rounded"
              type="button"
              onClick={() => {
                setOpenTab(1);
              }}
            >
              Back
            </button>
            <button className="px-3 py-2 outline outline-1 rounded" type="submit">
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
