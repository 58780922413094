import { IoMdPerson } from "react-icons/io";

export function ImageComp({ developer, setImageModal }) {
  return (
    <div className="">
      {developer && developer.avatar_url ? (
        <img
          className="overflow-hidden w-20 h-20 md:w-28 md:h-28 rounded-full border-2 md:border-4 border-white cursor-pointer"
          src={developer.avatar_url}
          alt={developer.fullname}
          loading="lazy"
          onClick={() => setImageModal(true)}
        />
      ) : (
        <div className="overflow-hidden w-20 h-20 md:w-28 md:h-28 rounded-full border-2 md:border-4 border-white bg-gray-100 flex justify-center items-center">
          <IoMdPerson size={64} className="text-gray-300" />
        </div>
      )}
    </div>
  );
}
