import { useState, useEffect } from "react";
import { Part1 } from "../Part1";
import { Part2 } from "../Part2";
import { Part3 } from "../Part3";
import { supabase } from "helpers";

export function AddJobPost() {
  const [openTab, setOpenTab] = useState(0);
  const [show, setShow] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [initialValues, setInitialValues] = useState({
    job_title: "",
    job_type: "",
    skill_set: [],
    job_description: "",
    skill: "",
    stages: [
      {
        stage_name: "",
        stage_description: "",
        vendor: "",
        type: "",
      },
      {
        stage_name: "",
        stage_description: "",
        vendor: "",
        type: "",
      },
    ],
    form: [
      {
        id: 0,
        sequenceNumber: 1,
        name: "layout",
        label: "layout",
        required: false,
        data_type: "text",
        type: "section",
        layout: [
          {
            id: 13,
            name: "email",
            label: "Email",
            required: true,
            data_type: "text",
            type: "input",
            class: "",
          },
          {
            id: 14,
            name: "phone",
            label: "Phone number",
            required: true,
            data_type: "text",
            type: "input",
            class: "",
          },
        ],
        class: "",
      },
      {
        id: 1,
        sequenceNumber: 3,
        name: "file",
        label: "file",
        required: false,
        data_type: "text",
        type: "section",
        layout: [
          {
            id: 32,
            sequenceNumber: 2,
            name: "file",
            label: "file",
            required: false,
            data_type: "text",
            type: "file",
            class: "",
          },
        ],
        class: "",
      },
    ],
  });

  const fetchVendors = async () => {
    const { data, error } = await supabase.from("vendors").select();
    setVendors(() => data);
  };

  useEffect(() => {
    fetchVendors().catch((error) => console.log("Error: ", console.log(error)));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [openTab]);

  return (
    <div className="w-full">
      <div className="bg-white w-full">
        <div className="border-b-[1px] border-gray-200 grid grid-cols-3 text-xs md:text-sm text-gray-500 md:justify-between">
          <button
            className={`px-3 py-2 md:px-6 md:py-4 text-left border-[0.5px] border-gray-300 w-full ${
              openTab === 0 && "text-ablestate-coral"
            }`}
            type="button"
          >
            1. Job Details
          </button>
          <button
            className={`px-3 md:px-6 md:py-4 text-left border-[0.5px] border-gray-300 md:w-full  ${
              openTab === 1 && "text-ablestate-coral"
            }`}
            type="button"
          >
            2. Stages
          </button>
          <button
            className={`px-3 py-2 md:px-6 md:py-4 text-left border-[0.5px] border-gray-300 md:w-full  ${
              openTab === 2 && "text-ablestate-coral"
            }`}
            type="button"
          >
            3. Application Form
          </button>
        </div>
        <main className="px-10 py-5">
          <div className="w-full justify-between flex my-5">
            <h2 className="font-medium text-xl font-raleway">
              {openTab === 0 ? "Job Details" : openTab === 1 ? "Stages" : "Application Form"}{" "}
            </h2>
            <div>
              <button
                type="button"
                className="text-sm border-[1px] border-gray-400 text-gray-500 px-3 py-2 rounded-md"
                onClick={() => {
                  // localStorage.setItem("form-status", JSON.stringify(initialValues));

                  const savedValues = JSON.parse(localStorage.getItem("form-status"));

                  setInitialValues(savedValues);
                }}
              >
                use save
              </button>
              <button
                type="button"
                className="text-sm border-[1px] border-gray-400 text-gray-500 px-3 py-2 rounded-md"
                onClick={() => {
                  localStorage.setItem("form-status", JSON.stringify(initialValues));
                }}
              >
                save progress
              </button>
            </div>
          </div>
          {openTab === 0 ? (
            <Part1
              show={show}
              setShow={setShow}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              setOpenTab={setOpenTab}
            />
          ) : openTab === 1 ? (
            <Part2
              vendors={vendors}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              setOpenTab={setOpenTab}
            />
          ) : (
            <Part3 initialValues={initialValues} setInitialValues={setInitialValues} setOpenTab={setOpenTab} />
          )}
        </main>
      </div>
    </div>
  );
}
