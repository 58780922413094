export function PickElement({ setInitialValues }) {
  return (
    <>
      <div className="border-b-[1px] mb-5 flex justify-between items-center">
        <h2 className="w-full px-3 py-2">Elements</h2>
      </div>
      <div className="px-3 py-2">
        <div className="py-2 border-b-[1px] mb-3 text-gray-500">
          <h2 className="text-sm mb-2">Text</h2>
          <div className="flex flex-wrap gap-2 text-xs text-gray-500">
            <button
              className="outline outline-1 rounded px-3 py-2"
              onClick={(event) => {
                event.preventDefault();
                setInitialValues((prev) => {
                  return {
                    ...prev,
                    form: [
                      ...prev["form"],
                      {
                        id: prev.form.length,
                        sequenceNumber: 4,
                        name: "file",
                        label: "file",
                        required: false,
                        data_type: "text",
                        type: "section",
                        layout: [
                          {
                            id: +`${prev.form.length}1`,
                            type: "p",
                            label: "This is a demonstration of formBuilder running in a React project.",
                            class: "",
                          },
                        ],
                        class: "",
                      },
                    ],
                  };
                });
              }}
            >
              Paragraph
            </button>
            <button
              className="outline outline-1 rounded px-3 py-2"
              onClick={(event) => {
                event.preventDefault();

                setInitialValues((prev) => {
                  return {
                    ...prev,
                    form: [
                      ...prev["form"],
                      {
                        id: prev.form.length,
                        sequenceNumber: 4,
                        name: "file",
                        label: "file",
                        required: false,
                        data_type: "text",
                        type: "section",
                        layout: [
                          {
                            id: +`${prev.form.length}1`,
                            type: "h2",
                            label: "Heading 1",
                            class: "text-xl md:text-2xl mb-4 font-medium text-red-500",
                          },
                        ],
                        class: "",
                      },
                    ],
                  };
                });
              }}
            >
              Heading
            </button>
            <button
              className="outline outline-1 rounded px-3 py-2"
              onClick={(event) => {
                event.preventDefault();

                setInitialValues((prev) => {
                  return {
                    ...prev,
                    form: [
                      ...prev["form"],
                      {
                        id: prev.form.length,
                        type: "button",
                        label: "Submit",
                        class: "",
                      },
                    ],
                  };
                });
              }}
            >
              Button
            </button>
          </div>
        </div>
        <div className="mb-4"></div>
        <div className="mb-4 text-sm text-gray-500">
          <h2 className="mb-1">Input</h2>
          <div className="flex flex-wrap gap-2 text-xs">
            <button
              className="outline outline-1 rounded px-3 py-2"
              onClick={(event) => {
                event.preventDefault();
                setInitialValues((prev) => {
                  return {
                    ...prev,
                    form: [
                      ...prev["form"],
                      {
                        id: prev.form.length,
                        name: "email",
                        label: "Label",
                        required: false,
                        data_type: "text",
                        type: "input",
                        class: "",
                      },
                    ],
                  };
                });
              }}
            >
              Text Field
            </button>
            <button
              className="outline outline-1 rounded px-3 py-2"
              onClick={(event) => {
                event.preventDefault();

                setInitialValues((prev) => {
                  return {
                    ...prev,
                    form: [
                      ...prev["form"],
                      {
                        id: prev.form.length,
                        name: "email",
                        label: "Label",
                        required: false,
                        data_type: "text",
                        type: "textarea",
                        class: "",
                      },
                    ],
                  };
                });
              }}
            >
              Text Area
            </button>
            <button
              className="outline outline-1 rounded px-3 py-2"
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              Checkbox
            </button>
            <button
              className="outline outline-1 rounded px-3 py-2"
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              Dropdown
            </button>
            <button
              className="outline outline-1 rounded px-3 py-2"
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              Date Picker
            </button>
            <button
              className="outline outline-1 rounded px-3 py-2"
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              Radio Button
            </button>
            <button
              className="outline outline-1 rounded px-3 py-2"
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              File Upload
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
