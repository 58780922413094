import { Formik, Form, ErrorMessage } from "formik";
import { supabase } from "helpers";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { MdOutlineModeEdit, MdOutlineDeleteOutline, MdOutlineArrowBackIos, MdClose } from "react-icons/md";
import { useState, useEffect } from "react";
import { Modal } from "../Modal";
import { Input, TextArea } from "components/ui";

export function EditExperienceModal({ setBio, data }) {
  const [edit, setEdit] = useState(null);
  useEffect(() => {}, [edit]);
  useEffect(() => {}, []);

  const handleDelete = async (projectId) => {
    try {
      const { data, error } = await supabase.from("user_projects").delete().eq("id", projectId);

      if (error) {
        toast.error(`${error.message}`, { position: "top-center" });
      }

      if (data) {
        toast.success(`Deleted successful.`, {
          position: "top-center",
        });
      }
    } catch (error) {
      toast.error(`${error.message}`, { position: "top-center" });
    } finally {
    }
  };

  return (
    <Modal setBio={setBio}>
      <Formik
        initialValues={{
          title: edit?.title,
          experience: edit?.experience,
          live_url: edit?.live_url,
          start_date: edit?.start_date,
          end_date: edit?.end_date,
        }}
        enableReinitialize
        onSubmit={async (values, { resetForm }) => {
          try {
            const { data, error } = await supabase.from("user_projects").update(values).eq("id", edit.id);

            if (error) {
              toast.error(`${error.message}`, { position: "top-center" });
            }

            if (data) {
              toast.success(`Experience updated successful.`, {
                position: "top-center",
              });
            }
          } catch (error) {
            toast.error(`${error.message}`, { position: "top-center" });
          } finally {
            resetForm();
            setBio(false);
          }
          resetForm();
        }}
      >
        {({ handleChange, handleBlur, values }) => (
          <Form className="">
            <div className="border-b-[0.8px] border-gray-500 px-3 md:px-5 py-2 mb-1 flex justify-between sticky left-0 z-20 bg-white bg-opacity-80 right-0 top-0">
              {edit ? (
                <div className="flex gap-2 items-center">
                  <span
                    className="w-8 h-8 hover:bg-gray-200 flex justify-center items-center cursor-pointer rounded"
                    onClick={() => {
                      setEdit(false);
                    }}
                  >
                    <MdOutlineArrowBackIos size={22} />
                  </span>
                  <h2 className="font-semibold">{edit?.title}</h2>
                </div>
              ) : (
                <div className="flex gap-2 items-center">
                  <span
                    className="w-8 h-8 hover:bg-gray-200 flex justify-center items-center cursor-pointer rounded"
                    onClick={() => {
                      setBio(false);
                    }}
                  >
                    <MdClose size={22} />
                  </span>
                  <h2 className="font-semibold">Edit Experience</h2>
                </div>
              )}
              {edit && (
                <button className="bg-ablestate-orange text-white py-1 px-4 rounded-md text-sm" type="submit">
                  Save
                </button>
              )}
            </div>
            {edit && (
              <div>
                <div className="px-5 md:px-10 my-2 py-2">
                  <Input
                    label="Title *"
                    values={values}
                    name="title"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </div>
                <div className="px-5 md:px-10 my-2 py-2">
                  <TextArea
                    label="Description"
                    name="experience"
                    values={values}
                    white={true}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </div>
                <div className="px-5 md:px-10 my-5 py-2">
                  <Input
                    label="Link"
                    values={values}
                    name="live_url"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </div>
                <div className="">
                  <p className="px-5 md:px-10">Period</p>
                  <div className="flex flex-col md:flex-row">
                    <div className="px-5 md:px-10 py-2 w-full">
                      <p className="text-xs">Start Date</p>
                      <input
                        type="date"
                        id="start_date"
                        name="start_date"
                        value={values.start_date ? format(new Date(values.start_date), "yyyy-MM-dd") : ""}
                        className="block rounded px-2.5 py-2 w-full text-sm text-gray-900 border-[0.8px]"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage name="start_date">
                        {(msg) => (
                          <div className="text-xs text-red-500 text-left w-full">
                            <p>{msg}</p>
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="px-5 md:px-10 py-2 w-full">
                      <p className="text-xs">End Date</p>
                      <input
                        type="date"
                        id="end_date"
                        name="end_date"
                        value={values.end_date ? format(new Date(values.end_date), "yyyy-MM-dd") : ""}
                        className="block rounded px-2.5 py-2 w-full text-sm text-gray-900 border-[0.8px]"
                        placeholder="end_date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage name="end_date">
                        {(msg) => (
                          <div className="text-xs text-red-500 text-left w-full">
                            <p>{msg}</p>
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
      {!edit && (
        <div className="px-3 md:px-8 py-5">
          {data &&
            data?.length > 0 &&
            data.map((experience, index) => (
              <div className="border-b-[1px] py-3" key={index}>
                <div className="flex justify-between">
                  <h2 className="font-bold">{experience.title}</h2>
                  <div className="flex gap-1">
                    <MdOutlineModeEdit size={20} className="cursor-pointer" onClick={() => setEdit(experience)} />
                    <MdOutlineDeleteOutline
                      size={20}
                      className="cursor-pointer"
                      onClick={() => {
                        handleDelete(experience.id);
                      }}
                    />
                  </div>
                </div>
                <p className="text-sm text-gray-500">{experience.experience}</p>
                <p className="text-sm text-gray-500">{experience.role}</p>
                <div className="flex gap-1 text-gray-500 items-center">
                  <p className="text-xs">{format(new Date(experience.start_date), "MM/yyyy")}</p> -{" "}
                  <p className="text-xs">{format(new Date(experience.end_date), "MM/yyyy")}</p>
                </div>
              </div>
            ))}
        </div>
      )}
    </Modal>
  );
}
