import { Field } from "formik";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

export function FilterPanel({ expanded, setExpanded, data }) {
  const currentValue = data.name;

  return (
    <div className="flex flex-col p-4 rounded-sm bg-white">
      <div className="mb-1 flex justify-between">
        <span className="text-sm font-semibold">{data.label}</span>
        <button
          onClick={(event) => {
            event.preventDefault();
            setExpanded((expanded) => ({
              ...expanded,
              [currentValue]: !expanded[currentValue],
            }));
          }}
        >
          {expanded[currentValue] ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
        </button>
      </div>
      <div
        role="group"
        aria-labelledby="checkbox-group"
        className={`flex flex-col px-2 ${expanded[currentValue] ? "visible" : "hidden"} `}
      >
        {data.fields.map((field, index) => (
          <label className="flex items-center gap-2 text-sm font-light" key={index}>
            <Field
              type="checkbox"
              name={field.name}
              value={field.value}
              className="rounded-sm ring-0 focus:ring-0 border-1 focus:border-1 accent-[#f36c32]"
            />
            {field.label}
          </label>
        ))}
      </div>
    </div>
  );
}
