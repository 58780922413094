import { MdClose } from "react-icons/md";
import { useLockBodyScroll, useOnClickOutside } from "hooks";
import { useRef } from "react";

export function ImageModal({ avatar_url, fullname, setImageModal }) {
  const ref = useRef();
  useLockBodyScroll();
  useOnClickOutside(ref, () => setImageModal(false));
  return (
    <div className="h-screen w-screen bg-black bg-opacity-40 fixed top-0 left-0 bottom-0 right-0 z-20 flex justify-center items-center overflow-hidden">
      <div>
        <span
          className="w-8 h-8 hover:bg-gray-200 flex justify-center items-center cursor-pointer rounded"
          onClick={() => {
            setImageModal(false);
          }}
        >
          <MdClose size={22} />
        </span>
        <img
          className="overflow-hidden w-[80vw] md:w-[40vw] cursor-pointer rounded-md modalContain"
          src={avatar_url}
          alt={fullname}
          ref={ref}
        />
      </div>
    </div>
  );
}
