import { Link } from "react-router-dom";
import { MdVerified } from "react-icons/md";
import { IoMdPerson } from "react-icons/io";
import { useAuth } from "components/auth/AuthContext";

export function ProfileCard({ developer }) {
  const { user } = useAuth();

  return (
    <div className="w-full h-full">
      <div className="px-4 py-4 bg-ablestate-gunmetal-500 overflow-hidden rounded shadow-lg flex flex-col justify-between overflow-y-hidden h-full">
        <div className="grid grid-cols-4 mb-6">
          <div className="w-[40px] h-[40px] overflow-hidden flex justify-center items-center rounded-full">
            {developer && developer?.avatar_url ? (
              <img
                src={developer.avatar_url}
                alt={developer.fullname}
                className="w-16 border-white rounded-full"
              />
            ) : (
              <div className="w-[40px] h-[40px] rounded-full bg-gray-100 flex justify-center items-center">
                <IoMdPerson size={25} className="text-gray-300" />
              </div>
            )}
          </div>

          <header className="text-white w-full col-span-3">
            <h1 className="flex items-center gap-2 whitespace-nowrap text-ellipsis">
              {developer && developer.fullname}
              {developer && developer.developers && developer.developers.is_pro && (
                <MdVerified className="text-amber-400 my-2" />
              )}
            </h1>
            <p className="mb-2 text-sm font-light whitespace-nowrap text-ellipsis overflow-hidden w-8/12">
              {developer?.tagline ? developer.tagline : "Developer"}
            </p>
            <div className="flex flex-wrap gap-1">
              {developer &&
              developer?.user_skills &&
              developer.user_skills.length > 0 ? (
                developer.user_skills
                  .slice(0, 3)
                  .map(({ skill: { skill_name: skill } }, index) => (
                    <span
                      className="bg-ablestate-gunmetal-300 px-2 py-1 rounded text-xs"
                      key={index}
                    >
                      {skill}
                    </span>
                  ))
              ) : (
                <span className="bg-ablestate-gunmetal-300 px-2 py-1 rounded text-xs">
                  ...
                </span>
              )}
              {developer &&
                developer?.user_skills &&
                developer.user_skills.length > 3 && (
                  <span className="bg-ablestate-gunmetal-300 px-2 py-1 rounded text-xs">
                    ...
                  </span>
                )}
            </div>
          </header>
        </div>
        {developer && (
          <Link
            to={`/developers/${developer?.handle_pool?.handle}`}
            className="w-full  bg-ablestate-orange hover:bg-ablestate-coral rounded text-white p-1 text-center"
            onClick={() => {
              if (!user) {
                sessionStorage.setItem(
                  "profile",
                  `/developers/${developer?.handle_pool?.handle}`
                );
              }
            }}
          >
            View Profile
          </Link>
        )}
      </div>
    </div>
  );
}
