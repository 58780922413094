import { useState } from "react";
import "./upload.css";
import { AiFillFileText } from "react-icons/ai";
import UploadIcon from "./UploadIcon";
import { IoMdClose } from "react-icons/io";

export default function UploadFile({
  imageUrl,
  setImageUrl,
  fileName,
  setFileName,
  required = false,
}) {
  return (
    <div className="upload-wrapper w-full px-1">
      <div
        id="form"
        className="outline outline-1"
        onClick={() => {
          document.querySelector(".input-file").click();
        }}
      >
        <input
          type="file"
          className="input-file"
          hidden
          required={required}
          defaultValue={""}
          onChange={(event) => {
            event.target.files[0] && setFileName(event.target.files[0].name);
            if (event.target.files) {
              setImageUrl(URL.createObjectURL(event.target.files[0]));
            }
          }}
          name="file"
          accept="application/pdf, application/docx, application/doc"
        />
        {fileName ? (
          <div>
            <iframe className="h-full" src={imageUrl}></iframe>
          </div>
        ) : (
          <>
            <UploadIcon />
            <p>Upload Resume (PDF)</p>
          </>
        )}
      </div>
      <section className="mt-2">
        {fileName ? (
          <li className="outline outline-1 rounded px-3 py-2 flex gap-2 items-center">
            <AiFillFileText className="uploaded-icon" />
            <div className="flex w-full items-center justify-between">
              {fileName}
              <IoMdClose
                className="cursor-pointer"
                onClick={() => {
                  setFileName(null);
                  setImageUrl(null);
                }}
                size={18}
              />
            </div>
          </li>
        ) : (
          <p className="outline outline-1 rounded px-3 py-2 flex gap-2 items-center">
            No Selected File
          </p>
        )}
      </section>
    </div>
  );
}
