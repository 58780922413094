import { MdOutlineBookmark, MdOutlineBookmarkBorder } from "react-icons/md";
import { GoLocation } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ErrorBoundary from "../../to-be-cleaned/ErrorBoundary";
import { getTimeAgo } from "./timeage";
import PropTypes from "prop-types";

export const JobCard = ({ job }) => {
  const navigate = useNavigate();
  const [myBookmarks, setMyBookmarks] = useState(JSON.parse(localStorage.getItem("bookmarks")) || []);
  const timeago = getTimeAgo(job.created_at);

  return (
    <div className="bg-white px-5 py-4 w-full flex flex-col md:flex-row gap-1 justify-between h-fit border-gray-300 rounded-md shadow-sm">
      <ErrorBoundary fallback={<p>Something went wrong</p>}>
        <div className="flex gap-3 text-sm font-bold">
          <div className="flex flex-col justify-between">
            <h2 className="flex flex-wrap items-center md:text-lg font-semibold">
              {job?.job_title} <span className="font-medium mx-1 w-1 h-1 rounded-full bg-black"></span>{" "}
              <span className="text-sm text-gray-500">
                {job.job_type === "full_time" && "Full Time"}
                {job.job_type === "part_time" && "Part Time"}
              </span>
            </h2>

            <p className="text-sm font-extralight text-ablestate-gunmetal-300">
              Budget: <span className="text-ablestate-orange">{job.job_meta_data.salary_expectation}</span>
            </p>
            <div className="flex items-center text-sm font-extralight text-ablestate-gunmetal-300">
              <GoLocation size={14} className="mr-1" />
              <p className="m-0 p-0">{job.locations.location}</p>
            </div>
            {job.job_skill_requirements && (
              <div className="flex gap-2 text-xs my-2 flex-wrap px-3 py-2">
                {job.job_skill_requirements &&
                  job.job_skill_requirements.map((skill, index) => (
                    <div key={index} className="border-[1px] px-2 py-1 rounded-md font-light flex items-center">
                      {skill.skill.skill_name}
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
        <div className="w-full md:w-fit flex flex-col gap-2 items-end justify-between">
          <div className="flex items-center gap-2 w-full justify-between">
            <p className="text-xs text-ablestate-gunmetal-300 min-w-20">{timeago}</p>
            {myBookmarks && myBookmarks.includes(job.id) ? (
              <MdOutlineBookmark
                size={22}
                className="cursor-pointer"
                onClick={(event) => {
                  setMyBookmarks((prev) => {
                    localStorage.setItem("bookmarks", JSON.stringify(prev.filter((bookmark) => bookmark !== job.id)));
                    return prev.filter((bookmark) => bookmark !== job.id);
                  });
                }}
              />
            ) : (
              <MdOutlineBookmarkBorder
                size={22}
                className="cursor-pointer"
                onClick={(event) => {
                  setMyBookmarks((prev) => {
                    if (prev) {
                      try {
                        localStorage.setItem("bookmarks", JSON.stringify([...prev, job.id]));
                      } catch (error) {}

                      return [...prev, job.id];
                    } else {
                      try {
                        localStorage.setItem("bookmarks", JSON.stringify([job.id]));
                      } catch (error) {}

                      return [job.id];
                    }
                  });
                }}
              />
            )}
          </div>
          <button
            className="rounded outline outline-1 px-5 py-2 bg-black text-white text-sm w-full"
            onClick={() => {
              navigate(`/developer/jobs/${job.id}`);
            }}
            name="viewJob1"
          >
            View
          </button>
        </div>
      </ErrorBoundary>
    </div>
  );
};

JobCard.propTypes = {
  job: PropTypes.object,
};
