import { useContext, useState, useEffect, createContext } from "react";
import { getUserRole } from "helpers/utils/authentication";
import { io } from "socket.io-client";
import { supabase } from "helpers";
import { useLocalStorage } from "hooks";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [role, setRole] = useState("");
  const [upMessages, setUpMessages] = useLocalStorage("showMessages", false);
  const [messageNotifications, setMessageNotifications] = useState(0);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [socket, setSocket] = useState(
    io("https://socket-io-charles.onrender.com", {
      autoConnect: false,
      transports: ["websocket"],
    })
  );

  if (user && socket) {
    socket.auth = { user_id: user?.id };
    socket.connect();
  }

  useEffect(() => {
    supabase.auth.getSession().then(async ({ data: { session } }) => {
      if (session && session?.user) {
        getUserRole(session.user.id)
          .then((role) => {
            setRole(role);
            setUser(session?.user ?? null);
            sessionStorage.setItem("role", role);
          })
          .catch(async (error) => {
            if (
              error.message ===
              "(intermediate value).data[Symbol.iterator]().next().value is undefined"
            ) {
              await supabase.auth.signOut();
            } else {
            }
            setUser(null);
          });
      } else {
        setUser(null);
        setPageLoading(false);
      }
    });

    // listen for changes to auth
    const { data } = supabase.auth.onAuthStateChange((_event, session) => {
      if (_event === "SIGNED_IN") {
        socket.emit("login", session.user.id);
        setSocket(socket);
      }

      if (session && session?.user) {
        getUserRole(session.user.id)
          .then((role) => {
            setRole(role);
            setUser(session?.user ?? null);
          })
          .catch((error) => console.log(error));
      } else {
        setUser(null);
        setRole(null);
      }
    });

    getMessageNotifications();
    return data ? () => data : () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  socket.on("receive_message", (data) => {
    getMessageNotifications();
  });

  const getMessageNotifications = async () => {
    supabase.auth.getSession().then(async ({ data: { session } }) => {
      if (session && session?.user) {
        const { data: messages } = await supabase
          .from("messages")
          .select()
          .eq("receiver_id", session?.user.id)
          .eq("seen ", false);

        if (messages) {
          setMessageNotifications(messages.length);
        }
      }
    });
  };

  const value = {
    user,
    role,
    setUser,
    setRole,
    socket,
    setSocket,
    upMessages,
    setUpMessages,
    selectedPerson,
    setSelectedPerson,
    messageNotifications,
    pageLoading,
    setPageLoading,
  };

  // use a provider to pass down the value
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
