import { Link, useNavigate } from "react-router-dom";
import { MdEdit } from "react-icons/md";

export function JobPost({ job }) {
  const navigate = useNavigate();

  return (
    <div className="bg-white w-52 px-5 py-5 shadow-sm rounded-md">
      <div className="flex items-center mb-2 justify-between">
        <span className="text-gray-500 font-light text-xs px-2 py-1 bg-gray-100 rounded-md">
          {job && job?.industry && job?.industry}
        </span>
        <MdEdit
          className="text-gray-400 cursor-pointer"
          onClick={() => {
            navigate(`/recruiter/edit-job/${job.id}`);
          }}
        />
      </div>
      <p className="font-medium my-2 text-sm ">{job && job?.job_title}</p>
      <p className="text-sm outline outline-1 outline-gray-200 bg-gray-100 text-gray-500 rounded-sm px-2 py-3">
        Candidates: {job?.job_applications ? job?.job_applications.length : 0}
      </p>
      <div className="mt-3 flex justify-end">
        <p className="text-xs text-gray-500 cursor-pointer">
          <Link to={`/recruiter/jobs/${job?.id}`}>See Details</Link>
        </p>
      </div>
    </div>
  );
}
