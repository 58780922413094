import { useState, useEffect } from "react";
import { supabase } from "helpers";
import { useParams } from "react-router-dom";
import { Heading } from "components";
import { Formik, Form } from "formik";
import { useLocalStorage } from "hooks";

function FeedbackDetails() {
  const [feedback, setFeedback] = useState([]);
  const [feedbackLength, setFeedbackLength] = useLocalStorage("feed-length", 0);
  const { id } = useParams();

  useEffect(() => {
    getFeedback();
  }, []);
  const getFeedback = async () => {
    const { data } = await supabase.from("feedback").select(`*, users(id, fullname)`).eq("id", id).single();

    if (data) {
      setFeedback(data);
      setFeedbackLength(data.length);
    }
  };

  return (
    <div className="px-5 md:px-12 lg:px-36 md:py-4 bg-ablestate-light min-h-screen w-full">
      <Heading title="Feedback Details" />

      <section className="flex flex-wrap gap-2 justify-between items-center mb-10 w-full">
        <div className="flex flex-col gap-2 md:gap-5">
          <div className="grid grid-cols-2 gap-2 md:gap-5">
            <p>Developer:</p>
            <p>{feedback && feedback.users && feedback.users.fullname}</p>
          </div>
          <div className="grid grid-cols-2 gap-2 md:gap-5">
            <p>Accomplished:</p>
            <p>{feedback && feedback.accomplished}</p>
          </div>
          <div className="grid grid-cols-2 gap-2 md:gap-5">
            <p>Best moment:</p>
            <p>{feedback && feedback.best_moment}</p>
          </div>
          <div className="grid grid-cols-2 gap-2 md:gap-5">
            <p>Worst moment:</p>
            <p>{feedback && feedback.worst_moment}</p>
          </div>
        </div>

        <div className="mb-5 select-none text-sm w-full">
          <Formik
            initialValues={{
              comment: "",
            }}
            onSubmit={async (values, { resetForm }) => {
              resetForm();
            }}
          >
            {({ values, handleChange, handleBlur }) => {
              return (
                <Form className="w-full px-5 md:px-0">
                  <div className="mt-3 relative w-full flex flex-col">
                    <textarea
                      type="text"
                      id="comment"
                      className="block px-2.5 pb-2.5 pt-4 w-full border-black text-gray-900 bg-transparent rounded appearance-none focus:outline-none peer focus:ring-0 focus:border-black border-[.9px] focus:border-[1px]"
                      placeholder=" "
                      name="comment"
                      onChange={handleChange("comment")}
                      onBlur={handleBlur("comment")}
                      value={values.comment}
                    />

                    <label
                      htmlFor="comment"
                      className="absolute text-gray-500 duration-300 transform -translate-y-4 scale-95 top-1 z-10 origin-[0] bg-[#f2f2f2] px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-95 peer-focus:-translate-y-4 left-1 pointer-events-none"
                    >
                      Comment
                    </label>
                  </div>
                  <input
                    value="submit"
                    type="submit"
                    className="cursor-pointer w-full rounded bg-ablestate-orange hover:bg-ablestate-coral text-white font-bold  my-6 py-3 px-8 transform transition hover:scale-[101%] duration-100 ease-in-out"
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </section>
    </div>
  );
}

export default FeedbackDetails;
