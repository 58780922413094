import { Star } from "../Icons/Star";
import { useAuth } from "components/auth/AuthContext";
import { supabase } from "helpers";

export function Criteria({ label, rating, dev_rating, developers, developer_id, setDevelopers, criteria_id }) {
  // const updateCriteria = (newRating) => {
  // setDevelopers((prevDevelopers) => {
  //   return prevDevelopers.map((developer) => {
  //     if (developer.id === developer_id) {
  //       return {
  //         ...developer,
  //         dev_rating: {
  //           ...developer.dev_rating,
  //           [criteria_id]: newRating,
  //         },
  //       };
  //     }
  //     return developer;
  //   });
  // });
  // };
  const { user } = useAuth();

  // console.log("siht: ", dev_rating);
  // console.log(developer_id);
  // console.log(developers);

  const updateCriteria = async (newRating) => {
    if (dev_rating) {
      const { data, error } = await supabase
        .from("dev_rating")
        .update({ [criteria_id]: newRating })
        .eq("dev_id", developer_id)
        .select();

      // console.log(error);
      // console.log(data);
    } else {
      const { data, error } = await supabase
        .from("dev_rating")
        .insert({ [criteria_id]: newRating, dev_id: developer_id })
        .select();

      // console.log(error);
      // console.log(data);
    }

    // setDevelopers(developers);

    setDevelopers((prevDevelopers) => {
      return prevDevelopers.map((developer) => {
        if (developer.id === developer_id) {
          return {
            ...developer,
            dev_rating: [
              {
                ...developer.dev_rating[0],
                [criteria_id]: newRating,
              },
              ...developer.dev_rating.slice(1), // Keep the rest of the array as it is
            ],
          };
        }
        return developer;
      });
    });

    // setDevelopers((prevDevelopers) => {
    //   return prevDevelopers.map((developer) => {
    //     if (developer.id === developer_id) {
    //       return {
    //         ...developer,
    //         dev_rating: {
    //           ...developer.dev_rating,
    //           [criteria_id]: newRating,
    //         },
    //       };
    //     }
    //     return developer;
    //   });
    // });
  };

  return (
    <div className="flex justify-between">
      <h2 className="font-light">{label}</h2>
      <div className="flex gap-2">
        <span onClick={() => updateCriteria(1)}>
          <Star checked={rating >= 1} />
        </span>
        <span onClick={() => updateCriteria(2)}>
          <Star checked={rating >= 2} />
        </span>
        <span onClick={() => updateCriteria(3)}>
          <Star checked={rating >= 3} />
        </span>
        <span onClick={() => updateCriteria(4)}>
          <Star checked={rating >= 4} />
        </span>
        <span onClick={() => updateCriteria(5)}>
          <Star checked={rating === 5} />
        </span>
      </div>
    </div>
  );
}
