import UploadFile from "components/ui/uploader/UploadFile";
import Section2 from "./FormFields/Section2";

function Default({ initialValues, setInitialValues, setCustom }) {
  return (
    <>
      <div className="flex justify-end">
        <button className="text-sm text-ablestate-orange underline cursor-pointer" onClick={() => setCustom(true)}>
          Customise
        </button>
      </div>
      <section className="mt-6 my-5">
        {initialValues &&
          initialValues.form.length > 0 &&
          initialValues.form.map((element, index) => {
            switch (element.type) {
              case "h2":
                return <h2 className={element.class}>{element.label}</h2>;
              case "p":
                return <p className={element.class}>{element.label}</p>;
              case "button":
                return (
                  <button className="px-3 py-2 outline outline-1 rounded" type="submit">
                    {element.label}
                  </button>
                );
              case "input":
                return (
                  <div className="relative rounded w-full">
                    <input
                      type={element.data_type}
                      id="phone_number"
                      className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] border-black focus:outline-none focus:ring-0 peer"
                      placeholder=" "
                      name="phone_number"
                    />
                    <label
                      htmlFor="job_title"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                    >
                      {element.label}
                      {element.required && <span className="text-red-400">*</span>}
                    </label>
                  </div>
                );
              case "textarea":
                return (
                  <div className="relative rounded w-full">
                    <textarea
                      type={element.data_type}
                      id="phone_number"
                      className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] border-black focus:outline-none focus:ring-0 peer"
                      placeholder=" "
                      name="phone_number"
                    />
                    <label
                      htmlFor="job_title"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                    >
                      {element.label}
                      {element.required && <span className="text-red-400">*</span>}
                    </label>
                  </div>
                );
              case "file":
                return (
                  <div className="my-3">
                    <UploadFile />
                  </div>
                );
              case "section":
                return <Section2 element={element} />;
              default:
                return null;
            }
          })}
      </section>
    </>
  );
}

export default Default;
