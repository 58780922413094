import { MdEdit } from "react-icons/md";
import UploadFile from "components/ui/uploader/UploadFile";

function Section2({ element }) {
  return (
    <div className="my-3">
      {element.layout.length > 0 ? (
        <div className={`py-5 rounded-md grid grid-cols-${element.layout.length} gap-3 md:gap-5`}>
          {element.layout.map((element, index) => {
            switch (element.type) {
              case "h2":
                return <h2 className={`${element.class} w-full`}>{element.label}</h2>;
              case "p":
                return <p className={element.class}>{element.label}</p>;
              case "button":
                return (
                  <button className="px-3 py-2 outline outline-1 rounded" type="submit">
                    {element.label}
                  </button>
                );
              case "input":
                return (
                  <div className="relative rounded w-full">
                    <input
                      type={element.data_type}
                      id="phone_number"
                      className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] border-black focus:outline-none focus:ring-0 peer"
                      placeholder=" "
                      name="phone_number"
                    />
                    <label
                      htmlFor="job_title"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                    >
                      {element.label}
                      {element.required && <span className="text-red-400">*</span>}
                    </label>
                  </div>
                );
              case "textarea":
                return (
                  <div className="relative rounded w-full px-3">
                    <textarea
                      type={element.data_type}
                      id="phone_number"
                      className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] border-black focus:outline-none focus:ring-0 peer"
                      placeholder=" "
                      name="phone_number"
                    />
                    <label
                      htmlFor="job_title"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                    >
                      {element.label}
                      {element.required && <span className="text-red-400">*</span>}
                    </label>
                  </div>
                );
              case "file":
                return <UploadFile />;
              default:
                return null;
            }
          })}
        </div>
      ) : (
        <div className="outline-1 outline-dashed px-5 py-5 rounded-md grid grid-cols-3 gap-5 md:gap-5">
          <div className="w-full h-12 md:h-20 bg-gray-200 cursor-pointer"></div>
          <div className="grid grid-cols-2 gap-1 cursor-pointer">
            <div className="w-full h-12 md:h-20 bg-gray-200"></div>
            <div className="w-full h-12 md:h-20 bg-gray-200"></div>
          </div>
          <div className="grid grid-cols-3 gap-1 cursor-pointer">
            <div className="w-full h-12 md:h-20 bg-gray-200"></div>
            <div className="w-full h-12 md:h-20 bg-gray-200"></div>
            <div className="w-full h-12 md:h-20 bg-gray-200"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Section2;
