import PropTypes from "prop-types";

export function BlockButton({
  loading = false,
  label,
  type = "primary",
  margin = true,
  large = false,
  submit = false,
}) {
  return (
    <button
      type={submit ? "submit" : "button"}
      className={`cursor-pointer w-full rounded  font-bold py-3 px-4 transform transition hover:scale-[101%] duration-100 ease-in-out ${
        margin && "my-6"
      } ${
        type === "outline"
          ? "border-[1px] border-ablestate-coral text-ablestate-coral"
          : "bg-ablestate-orange hover:bg-ablestate-coral text-white"
      }`}
    >
      {loading ? "Loading..." : label}
    </button>
  );
}

BlockButton.propTypes = {
  loading: PropTypes.bool,
  submit: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  large: PropTypes.bool,
};
