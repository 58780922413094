import { useEffect, useState } from "react";
import { supabase } from "helpers";
import { RatingCard } from "components/ui/Rating/RatingCard";
import { Formik, Form } from "formik";
import { useAuth } from "components/auth/AuthContext";
import { Thumb } from "components/ui/Rating/Icons/Thumb";

const Rating = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const getRatings = async () => {
      const { data } = await supabase.from("rating").select("*");
      // console.log("the rating is: ", data);
    };
    getRatings();

    const getDevRating = async () => {
      const { data } = await supabase.from("dev_rating").select("*");
      // console.log("the dev_rating is: ", data);
    };
    getDevRating();

    const getDevelopers = async () => {
      const { data } = await supabase
        .from("users")
        .select(
          `*, handle_pool(*), dev_rating(*), developers!inner(*, job_activity(*)), user_socials(*), user_roles (roles (
          role_name
        ))`,
        )
        .eq("developers.is_hired", true)
        .order("fullname", { ascending: true });

      if (data) {
        const myDevs = [];
        data.forEach((dev) => {
          const isHeShe = dev.developers?.job_activity.find((rec) => {
            return rec.recruiter_id === user.id;
          });

          if (isHeShe) {
            myDevs.push(dev);
          }
        });
        // setDevelopers(data);
        setDevelopers(myDevs);
      }
      setIsLoading(false);
    };

    getDevelopers();

    supabase
      .channel("developers")
      .on("postgres_changes", { event: "*", schema: "public", table: "users" }, () => getDevelopers())
      .on("postgres_changes", { event: "*", schedma: "public", table: "developers" }, () => getDevelopers())
      .subscribe();
  }, []);

  const { user } = useAuth();
  const [developers, setDevelopers] = useState([]);
  const [wouldHire, setWouldHire] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  // console.log(developers);

  return (
    <div className="pt-16 pb-10 mb-0 bg-ablestate-light w-screen px-5 md:px-12 lmd:px-20 lg:px-36 min-h-screen">
      {!isLoading && developers.length === 0 ? (
        "Not Devs yet"
      ) : !isLoading && developers.length > 0 ? (
        <div className="mb-5 pb-5 flex flex-col items-center bg-white px-3 py-5 md:py-12 md:px-10">
          <Formik
            initialValues={{ will_hire: wouldHire, comment: "", rec_id: user.id }}
            enableReinitialize
            onSubmit={async (values, { resetForm }) => {
              const { data: ratingAdded, error } = await supabase
                .from("rating")
                .insert({ will_hire: values.will_hire, comment: values.comment, rec_id: values.rec_id })
                .single()
                .select();

              if (ratingAdded) {
                developers.forEach(async (dev) => {
                  // const devRating = {
                  //   dev_id: dev.id,
                  //   time_keeping: dev.dev_rating[0]?.time_keeping,
                  //   technical_effectiveness: dev.dev_rating[0]?.technical_effectiveness,
                  //   job_focus: dev.dev_rating[0]?.job_focus,
                  //   communication: dev.dev_rating[0]?.communication,
                  //   team_work: dev.dev_rating[0]?.team_work,
                  //   rating_id: ratingAdded.id,
                  // };

                  // if (devRating.rating_id) {
                    const { data, error } = await supabase
                      .from("dev_rating")
                      .update({ rating_id: ratingAdded.id })
                      .eq("id", dev.dev_rating[0].id);
                    // const { data, error } = await supabase.from("dev_rating").insert(devRating).select();
                    // .eq("id", dev.dev_rating[0].id)
                  // }
                });
              }

              resetForm();
            }}
          >
            {({ values, handleChange, handleBlur }) => (
              <Form className="w-full">
                {developers &&
                  developers.map((developer, index) => (
                    <RatingCard
                      developer={developer}
                      developers={developers}
                      setDevelopers={setDevelopers}
                      key={index}
                    />
                  ))}

                <div className="px-3 py-2 w-full mt-5 md:mt-10">
                  <h2 className="mb-2">Would you hire with us again?</h2>
                  <div className="flex gap-5">
                    <Thumb label="yes" wouldHire={wouldHire} setWouldHire={setWouldHire} selected={wouldHire} />
                    <Thumb label="no" wouldHire={wouldHire} setWouldHire={setWouldHire} selected={!wouldHire} />
                  </div>
                </div>

                <div className="px-3 py-2 w-full mt-5 md:mt-10">
                  <h2 className="mb-2">Are there any additional comments?</h2>
                  <textarea
                    name="comment"
                    onChange={handleChange("comment")}
                    onBlur={handleBlur("comment")}
                    value={values.comment}
                    id="comment"
                    cols="30"
                    rows="10"
                    placeholder="Enter comment"
                    className="w-full bg-transparent rounded-sm"
                  ></textarea>
                </div>
                <div className="mt-5 flex justify-end w-full">
                  <input
                    type="submit"
                    className="cursor-pointer font-medium rounded-full border-[0.7px] border-ablestate-orange transform transition hover:scale-[104%] duration-100 ease-in-out text-center py-2 px-6 text-sm bg-ablestate-orange hover:bg-ablestate-coral text-white"
                    value="Submit"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <p>loading...</p>
      )}

      <div></div>
    </div>
  );
};

export default Rating;
