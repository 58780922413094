import PropTypes from "prop-types";
import { EditElement } from "./EditElement";
import { PickElement } from "./PickElement";

export function FormElement({ editID, setEditID, setShouldUpdate, initialValues, setInitialValues }) {
  let formInitialValues = {};

  const indexToUpdate =
    initialValues && initialValues?.form && editID && initialValues?.form.findIndex((element) => element.id === editID);

  if (editID > 9 && editID < 20) {
    const indexToUpdate2 =
      initialValues.form && editID && initialValues.form[0].layout.findIndex((element) => element.id === editID);
    formInitialValues = {
      label: initialValues.form[0].layout[indexToUpdate2] && initialValues.form[0].layout[indexToUpdate2].label,
      required:
        initialValues.form[0].layout[indexToUpdate2] && initialValues.form[0].layout[indexToUpdate2].type === "input"
          ? initialValues.form[0].layout[indexToUpdate2].required
          : "",
      class: initialValues.form[0].layout[indexToUpdate2] && initialValues.form[0].layout[indexToUpdate2].class,
      type: initialValues.form[0].layout[indexToUpdate2] && initialValues.form[0].layout[indexToUpdate2].type,
    };
  } else if (editID > 19 && editID < 29) {
    const indexToUpdate2 =
      initialValues.form && editID && initialValues.form[2].layout.findIndex((element) => element.id === editID);
    formInitialValues = {
      label: initialValues.form[2].layout[indexToUpdate2] && initialValues.form[2].layout[indexToUpdate2].label,
      required:
        initialValues.form[2].layout[indexToUpdate2] && initialValues.form[2].layout[indexToUpdate2].type === "input"
          ? initialValues.form[2].layout[indexToUpdate2].required
          : "",
      class: initialValues.form[2].layout[indexToUpdate2] && initialValues.form[2].layout[indexToUpdate2].class,
      type: initialValues.form[2].layout[indexToUpdate2] && initialValues.form[2].layout[indexToUpdate2].type,
    };
  } else if (editID > 29 && editID < 39) {
    const indexToUpdate2 =
      initialValues.form && editID && initialValues.form[3].layout.findIndex((element) => element.id === editID);

    formInitialValues = {
      label: initialValues.form[3].layout[indexToUpdate2] && initialValues.form[3].layout[indexToUpdate2].label,
      required:
        initialValues.form[3].layout[indexToUpdate2] && initialValues.form[3].layout[indexToUpdate2].type === "input"
          ? initialValues.form[3].layout[indexToUpdate2].required
          : "",
      class: initialValues.form[3].layout[indexToUpdate2] && initialValues.form[3].layout[indexToUpdate2].class,
      type: initialValues.form[3].layout[indexToUpdate2] && initialValues.form[3].layout[indexToUpdate2].type,
    };
  } else if (editID > 39 && editID < 49) {
    const indexToUpdate2 =
      initialValues.form && editID && initialValues.form[4].layout.findIndex((element) => element.id === editID);

    formInitialValues = {
      label: initialValues.form[4].layout[indexToUpdate2] && initialValues.form[4].layout[indexToUpdate2].label,
      required:
        initialValues.form[4].layout[indexToUpdate2] && initialValues.form[4].layout[indexToUpdate2].type === "input"
          ? initialValues.form[4].layout[indexToUpdate2].required
          : "",
      class: initialValues.form[4].layout[indexToUpdate2] && initialValues.form[4].layout[indexToUpdate2].class,
      type: initialValues.form[4].layout[indexToUpdate2] && initialValues.form[4].layout[indexToUpdate2].type,
    };
  } else if (editID > 49 && editID < 59) {
    const indexToUpdate2 =
      initialValues.form && editID && initialValues.form[5].layout.findIndex((element) => element.id === editID);

    formInitialValues = {
      label: initialValues.form[5].layout[indexToUpdate2] && initialValues.form[5].layout[indexToUpdate2].label,
      required:
        initialValues.form[5].layout[indexToUpdate2] && initialValues.form[5].layout[indexToUpdate2].type === "input"
          ? initialValues.form[5].layout[indexToUpdate2].required
          : "",
      class: initialValues.form[5].layout[indexToUpdate2] && initialValues.form[5].layout[indexToUpdate2].class,
      type: initialValues.form[5].layout[indexToUpdate2] && initialValues.form[5].layout[indexToUpdate2].type,
    };
  } else if (editID > 59 && editID < 69) {
    const indexToUpdate2 =
      initialValues.form && editID && initialValues.form[6].layout.findIndex((element) => element.id === editID);

    formInitialValues = {
      label: initialValues.form[6].layout[indexToUpdate2] && initialValues.form[6].layout[indexToUpdate2].label,
      required:
        initialValues.form[6].layout[indexToUpdate2] && initialValues.form[6].layout[indexToUpdate2].type === "input"
          ? initialValues.form[6].layout[indexToUpdate2].required
          : "",
      class: initialValues.form[6].layout[indexToUpdate2] && initialValues.form[6].layout[indexToUpdate2].class,
      type: initialValues.form[6].layout[indexToUpdate2] && initialValues.form[6].layout[indexToUpdate2].type,
    };
  } else if (editID > 69 && editID < 79) {
    const indexToUpdate2 =
      initialValues.form && editID && initialValues.form[7].layout.findIndex((element) => element.id === editID);

    formInitialValues = {
      label: initialValues.form[7].layout[indexToUpdate2] && initialValues.form[7].layout[indexToUpdate2].label,
      required:
        initialValues.form[7].layout[indexToUpdate2] && initialValues.form[7].layout[indexToUpdate2].type === "input"
          ? initialValues.form[7].layout[indexToUpdate2].required
          : "",
      class: initialValues.form[7].layout[indexToUpdate2] && initialValues.form[7].layout[indexToUpdate2].class,
      type: initialValues.form[7].layout[indexToUpdate2] && initialValues.form[7].layout[indexToUpdate2].type,
    };
  } else if (editID > 79 && editID < 89) {
    const indexToUpdate2 =
      initialValues.form && editID && initialValues.form[8].layout.findIndex((element) => element.id === editID);

    formInitialValues = {
      label: initialValues.form[8].layout[indexToUpdate2] && initialValues.form[8].layout[indexToUpdate2].label,
      required:
        initialValues.form[8].layout[indexToUpdate2] && initialValues.form[8].layout[indexToUpdate2].type === "input"
          ? initialValues.form[8].layout[indexToUpdate2].required
          : "",
      class: initialValues.form[8].layout[indexToUpdate2] && initialValues.form[8].layout[indexToUpdate2].class,
      type: initialValues.form[8].layout[indexToUpdate2] && initialValues.form[8].layout[indexToUpdate2].type,
    };
  } else if (editID > 89 && editID < 99) {
    const indexToUpdate2 =
      initialValues.form && editID && initialValues.form[9].layout.findIndex((element) => element.id === editID);

    formInitialValues = {
      label: initialValues.form[9].layout[indexToUpdate2] && initialValues.form[9].layout[indexToUpdate2].label,
      required:
        initialValues.form[9].layout[indexToUpdate2] && initialValues.form[9].layout[indexToUpdate2].type === "input"
          ? initialValues.form[9].layout[indexToUpdate2].required
          : "",
      class: initialValues.form[9].layout[indexToUpdate2] && initialValues.form[9].layout[indexToUpdate2].class,
      type: initialValues.form[9].layout[indexToUpdate2] && initialValues.form[9].layout[indexToUpdate2].type,
    };
  } else {
    formInitialValues = {
      label: initialValues.form[indexToUpdate] && initialValues.form[indexToUpdate].label,
      required:
        initialValues.form[indexToUpdate] && initialValues.form[indexToUpdate].type === "input"
          ? initialValues.form[indexToUpdate].required
          : "",
      class: initialValues.form[indexToUpdate] && initialValues.form[indexToUpdate].class,
      type: initialValues.form[indexToUpdate] && initialValues.form[indexToUpdate].type,
    };
  }

  return (
    <section className="w-full col-span-1 border-[1px] border-gray-300 bg-white rounded-md text-gray-600">
      {editID ? (
        <EditElement
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          formInitialValues={formInitialValues}
          indexToUpdate={indexToUpdate}
          editID={editID}
          setEditID={setEditID}
        />
      ) : (
        <PickElement setInitialValues={setInitialValues} />
      )}
    </section>
  );
}

FormElement.propTypes = {
  editID: PropTypes.number,
  initialValues: PropTypes.object,
};
