import PropTypes from "prop-types";

export function Heading({ title }) {
  return (
    <section className={["py-10"]}>
      <h1 className={["font-bold text-2xl"]}>{title}</h1>
    </section>
  );
}

Heading.propTypes = {
  title: PropTypes.string
}
