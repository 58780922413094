import { GrLocation } from "react-icons/gr";
import { CgShare } from "react-icons/cg";
import { MdOutlineBookmarkBorder } from "react-icons/md";

function ViewJobLoader() {
  return (
    <>
      <div className="bg-gray-100 w-64 rounded-md h-6 animate-pulse mb-4"></div>

      <div className="text-sm text-gray-500 flex gap-2">
        <GrLocation color="blue" />

        <div className="bg-gray-100 w-24 rounded-md h-4 mb-2 animate-pulse"></div>
      </div>
      <div className="bg-gray-100 w-20 rounded-md h-4 mb-2 animate-pulse"></div>
      <div className="flex flex-wrap items-end gap-3 mt-5">
        <h3>Salary:</h3>
        <div className="bg-gray-100 w-24 rounded-md h-4 mb-2 animate-pulse"></div>
      </div>
      <div className="flex flex-wrap items-end gap-3 mt-2">
        <h3>Type:</h3>
        <div className="bg-gray-100 w-24 rounded-md h-4 mb-2 animate-pulse"></div>
      </div>
      <div className="flex flex-wrap items-end gap-3 mt-2">
        <h3>Experience:</h3>
        <div className="bg-gray-100 w-28 rounded-md h-4 mb-2 animate-pulse"></div>
      </div>
      <div className="py-5 flex gap-2 flex-wrap">
        <div className="text-white bg-[#1D1F20] text-sm px-4 md:px-6 py-2 rounded-full">Apply now</div>
        <button className="border-[1px] border-[#1D1F20] text-[#1d1f20] text-sm px-4 md:px-6 py-2 rounded-full flex gap-1 items-center">
          <>
            <MdOutlineBookmarkBorder size={18} />
            Watch
          </>
        </button>
        <button className="border-[1px] border-[#1D1F20] text-[#1d1f20] text-sm px-4 md:px-6 py-2 rounded-full flex gap-1 items-center relative">
          <CgShare size={18} />
          Share
        </button>
      </div>
      <div className="mb-10">
        <h2 className="font-medium">About the Job</h2>
        <div className="bg-gray-100 w-full mb-2 rounded-md h-3 animate-pulse mt-4"></div>
        <div className="bg-gray-100 w-full mb-2 rounded-md h-3 animate-pulse"></div>
        <div className="bg-gray-100 w-10/12 rounded-md h-3 mb-2 animate-pulse"></div>
        <div className="bg-gray-100 w-10/12 rounded-md h-3 mb-2 animate-pulse"></div>
        <div className="bg-gray-100 w-8/12 mb-2 rounded-md h-3 animate-pulse"></div>
        <div className="bg-gray-100 w-8/12 mb-2 rounded-md h-3 animate-pulse"></div>
        <div className="bg-gray-100 w-32 mb-2 rounded-md h-3 animate-pulse"></div>
      </div>
      <div className="mb-10">
        <h2 className="font-medium">Requirements</h2>
        <ul className="mb-2">
          <li className="bg-gray-100 w-24 mb-2 rounded-md h-3 animate-pulse"></li>
          <li className="bg-gray-100 w-24 mb-2 rounded-md h-3 animate-pulse"></li>
          <li className="bg-gray-100 w-24 mb-2 rounded-md h-3 animate-pulse"></li>
          <li className="bg-gray-100 w-24 mb-2 rounded-md h-3 animate-pulse"></li>
          <li className="bg-gray-100 w-24 mb-2 rounded-md h-3 animate-pulse"></li>
        </ul>
      </div>
    </>
  );
}

export default ViewJobLoader;
