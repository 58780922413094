import { Formik, Form, ErrorMessage } from "formik";
import { IoClose } from "react-icons/io5";
import { useState, useEffect } from "react";
import { supabase } from "helpers";
import { MdClose } from "react-icons/md";
import { Modal } from "../Modal";
import { BlockButton, SkillBadge } from "components/ui";
import { skillValidationSchema } from "helpers";

export function SkillModal({ setBio, data }) {
  const [suggestedSkills, setSuggestedSkills] = useState([]);
  const [currentSkills, setCurrentSkills] = useState([]);
  const [possessedSkills, setPossessedSkills] = useState(data);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchSkills()
      .then(fetchDeveloperSkills())
      .then(() => setLoading(false))
      .catch((error) => console.log(error));
  }, []);

  const selectOption = (option) => {
    setCurrentSkills((currentSkills) =>
      [...currentSkills, { skill: { skill_name: option } }].filter(
        (skill, index, array) =>
          index === array.findIndex((duplicate) => skill.skill.skill_name === duplicate.skill.skill_name),
      ),
    );

    setPossessedSkills((currentSkills) =>
      [...currentSkills, { skill: { skill_name: option } }].filter(
        (skill, index, array) =>
          index === array.findIndex((duplicate) => skill.skill.skill_name === duplicate.skill.skill_name),
      ),
    );

    setSuggestedSkills((currentSkills) => currentSkills.filter(({ skill_name }) => skill_name !== option));
  };

  const clearOption = (option) => {
    setCurrentSkills((currentSkills) => currentSkills.filter(({ skill: { skill_name } }) => skill_name !== option));

    setSuggestedSkills((currentSkills) => {
      return [...currentSkills, { skill_name: option }];
    });

    setPossessedSkills((currentSkills) => currentSkills.filter(({ skill: { skill_name } }) => skill_name !== option));
  };

  const fetchSkills = async () => {
    const possessedSkills = data.map((skill) => skill.skill.skill_name);
    const { data: allSkills, error } = await supabase.from("skill").select("skill_name").limit(10);

    const MissingSkills = allSkills.filter((skill) => !possessedSkills.includes(skill.skill_name));

    if (error) console.log(error);
    setSuggestedSkills(MissingSkills);
  };

  const fetchDeveloperSkills = async () => {
    const { data: skills, error } = await supabase.from("user_skills").select(`
        user_id,
        skill_level,
        assessed,
        interviewed,
        created_at,
        updated_at,
        user_skill_meta,
        skill (
          id,
          skill_name
        )
      `);

    if (error) throw error;
    setCurrentSkills(skills);
  };

  const initialValues = {
    skill_name: "",
  };

  return (
    <Modal setBio={setBio} title="Edit Skills">
      <Formik
        initialValues={initialValues}
        validationSchema={skillValidationSchema}
        onSubmit={async ({ skill_name }, { resetForm }) => {
          if (skill_name) {
            const skills = skill_name ? [...possessedSkills, { skill: { skill_name } }] : possessedSkills;

            try {
              const { error } = await supabase.rpc("update_user_skills", {
                skills: JSON.stringify(skills),
              });
              if (error) throw error;
            } catch (error) {
            } finally {
              resetForm({ values: initialValues });
              setBio(false);
            }
          }
        }}
      >
        {({ handleChange, handleBlur }) => (
          <Form className="">
            <div className="border-b-[1px] border-gray-500 px-3 md:px-5 py-2 pb-5 flex justify-between sticky left-0 z-30 bg-white bg-opacity-90 right-0 top-0">
              <div className="flex gap-2 items-center">
                <span
                  className="w-8 h-8 hover:bg-gray-200 flex justify-center items-center cursor-pointer rounded"
                  onClick={() => {
                    setBio(false);
                  }}
                >
                  <MdClose size={22} />
                </span>
                <h2 className="font-semibold">Edit Skill</h2>
              </div>
              <button className="bg-ablestate-orange text-white py-1 px-4 rounded-md text-sm" type="submit">
                Save
              </button>
            </div>
            {loading ? (
              <div className="w-full h-full flex justify-center items-center absolute">
                <span>loading</span>
              </div>
            ) : (
              <>
                <section className=" px-5 md:px-10 my-5 py-2">
                  <p>New Skill*</p>
                  <div className="grid grid-cols-3 gap-1">
                    <div className="w-full col-span-2 h-full">
                      <input
                        type="text"
                        id="skill_name"
                        name="skill_name"
                        className="block rounded px-2.5 py-2 w-full text-sm text-gray-900 border-[0.6px] border-black col-span-2 h-full"
                        placeholder="New Skill"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage name="skill_name">
                        {(msg) => (
                          <div className="text-xs text-red-500 text-left w-full">
                            <p className="error-messages">{msg}</p>
                          </div>
                        )}
                      </ErrorMessage>
                    </div>

                    <BlockButton label="Add" submit={true} className="my-0" margin={false} type="outline" />
                  </div>
                </section>
                <section className="mb-5 border-[1px] border-gray-400 mx-5 md:mx-10 rounded-md p-3 md:p-5">
                  <p>Suggested Skills</p>
                  <div className="flex flex-wrap gap-2 py-2 px-2 md:px-4">
                    {suggestedSkills &&
                      suggestedSkills.length > 0 &&
                      suggestedSkills.map(({ skill_name }, index) => (
                        <SkillBadge
                          key={index}
                          skill={skill_name}
                          clickable={true}
                          handleClick={() => selectOption(skill_name)}
                        />
                      ))}
                  </div>
                </section>
                <section className="mb-5 border-[1px] border-gray-400 mx-5 md:mx-10 rounded-md p-2 md:p-5">
                  {!currentSkills || currentSkills?.length < 1 ? (
                    <p>No skills to show sofar</p>
                  ) : (
                    <>
                      <p>Skills you have so far</p>
                      <div className="flex flex-wrap gap-2 py-2 px-4">
                        {possessedSkills &&
                          possessedSkills?.length > 0 &&
                          possessedSkills.map(({ skill: { skill_name } }, index) => (
                            <div
                              className="flex cursor-pointer rounded-full border-[1px] border-black pl-4 pr-2 py-1 text-xs md:text-sm gap-2"
                              key={index}
                            >
                              {skill_name}
                              <div
                                className="flex justify-center items-center rounded-full w-5 h-5 bg-ablestate-orange text-white"
                                onClick={() => {
                                  clearOption(skill_name);
                                }}
                              >
                                <IoClose />
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                </section>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
