export function Star({ checked, width = "24", height = "24" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer"
    >
      <path
        d="M17.1195 2.67065L20.7538 10.0078C20.8365 10.194 20.9665 10.3553 21.1309 10.4756C21.2953 10.596 21.4884 10.6711 21.6909 10.6935L29.7138 11.8821C29.9461 11.9119 30.1651 12.0073 30.3453 12.157C30.5254 12.3066 30.6592 12.5045 30.7311 12.7274C30.803 12.9503 30.81 13.189 30.7513 13.4158C30.6926 13.6425 30.5706 13.8478 30.3995 14.0078L24.6167 19.7449C24.4691 19.8828 24.3583 20.0554 24.2944 20.2471C24.2306 20.4387 24.2156 20.6432 24.2509 20.8421L25.6452 28.9107C25.6856 29.1425 25.6601 29.381 25.5716 29.5991C25.4831 29.8171 25.3352 30.006 25.1446 30.1441C24.9541 30.2822 24.7286 30.3641 24.4939 30.3804C24.2591 30.3967 24.0245 30.3468 23.8167 30.2364L16.5938 26.4192C16.4089 26.3284 16.2056 26.2812 15.9995 26.2812C15.7935 26.2812 15.5902 26.3284 15.4052 26.4192L8.18237 30.2364C7.97458 30.3468 7.73994 30.3967 7.50518 30.3804C7.27041 30.3641 7.04494 30.2822 6.85441 30.1441C6.66388 30.006 6.51595 29.8171 6.42745 29.5991C6.33895 29.381 6.31343 29.1425 6.3538 28.9107L7.74809 20.7507C7.78343 20.5518 7.76848 20.3472 7.70461 20.1556C7.64073 19.964 7.52996 19.7914 7.38238 19.6535L1.53095 14.0078C1.35783 13.8434 1.23609 13.6324 1.18046 13.4003C1.12484 13.1682 1.13772 12.9249 1.21755 12.6999C1.29737 12.4749 1.44072 12.278 1.63022 12.1328C1.81972 11.9876 2.04724 11.9006 2.28523 11.8821L10.3081 10.6935C10.5106 10.6711 10.7037 10.596 10.8681 10.4756C11.0326 10.3553 11.1626 10.194 11.2452 10.0078L14.8795 2.67065C14.9785 2.45696 15.1365 2.27604 15.335 2.14924C15.5334 2.02245 15.764 1.95508 15.9995 1.95508C16.235 1.95508 16.4656 2.02245 16.6641 2.14924C16.8625 2.27604 17.0205 2.45696 17.1195 2.67065Z"
        fill={checked ? "#FFC84E" : "#D6D6D6"}
        stroke={checked ? "#FFC84E" : "#D6D6D6"}
        strokeWidth="0.714286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
