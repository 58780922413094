function JobStatusButton({ tab, setTab, count, active }) {
  return (
    <button
      className={`px-3 py-2 ${
        tab ? "text-gray-800 font-medium" : "text-gray-500"
      }`}
      onClick={() => setTab(active ? 1 : 2)}
    >
      {active ? "Active Job Posts" : "Inactive Job Posts"}({count})
    </button>
  );
}

export default JobStatusButton;
