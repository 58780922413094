export const humanLanguagesList = [
  "Arabic",
  "Duetsch",
  "Dutch",
  "English UK",
  "English US",
  "French",
  "Spanish",
  "Portuguese",
];
