function Logo({ showLabel, logoLabel, dimensions = "w-[2rem] h-[2-rem]", iconColor }) {
  return (
    <section className="flex gap-2 items-center">
      <div
        className={`${dimensions} ${
          iconColor === "bg-white" ? "bg-ablestate-gunmetal" : "bg-white"
        } rounded-full relative flex justify-center items-center p-2`}
      >
        {/* <span className="absolute bg-black w-[95%] h-[95%] rounded-full"></span> */}
        <span className={`absolute ${iconColor} w-[77%] h-[77%] rounded-full -right-[0]`}></span>
        {/* <span className="absolute bg-ablestate-gunmetal w-[60%] h-[60%] rounded-full right-[0.5%]"></span> */}
      </div>
      {showLabel && <span className="text-3xl">{logoLabel}</span>}
    </section>
  );
}

export default Logo;
