import { MdOutlineAddAPhoto, MdOutlineClose, MdClose } from "react-icons/md";
import { Formik, Form } from "formik";
import {
  supabase,
  developerBioValidationSchema,
  programmingLanguages,
} from "helpers";
import { toast } from "react-toastify";
import ImageUpload from "components/to-be-cleaned/ImageUpload";
import { uploadAvatar, uploadBackground } from "helpers/utils/uploadFiles";
import { useAuth } from "components/auth/AuthContext";
import { generateURL } from "helpers/utils/downloadFiles";
import { countryList } from "helpers/countryList";
import { humanLanguagesList } from "helpers/humanLanguagesList";
import { useState, useEffect } from "react";
import { Modal } from "../Modal";

export function BioModal({ setBio, data, bgColor, setBgColor }) {
  const {
    user: { id: user_id },
    role,
  } = useAuth();
  const initialValues = {
    fullname: data?.fullname,
    tagline: data?.tagline,
    primary_programming_language: data?.developers
      ? data?.developers[0]?.primary_programming_language ?? ""
      : "",
    handle: data?.handle_pool?.handle ?? "",
    current_handle: data?.handle_pool?.handle,
    handle_id: data?.handle_pool?.id,
    phone:
      (data?.user_socials &&
        data.user_socials?.length > 0 &&
        data.user_socials.find((social) => social?.social_type === "phone")
          ?.social_value) ??
      "",
    gender: data?.gender ?? "",
    human_language: data?.developers
      ? data?.developers?.[0]?.human_language
      : "",
    bio: data?.bio,
    github:
      (data?.user_socials &&
        data.user_socials?.length > 0 &&
        data.user_socials.find((social) => social?.social_type === "github")
          ?.social_value) ??
      "",
    linkedin:
      (data?.user_socials &&
        data.user_socials?.length > 0 &&
        data.user_socials.find((social) => social?.social_type === "linkedin")
          ?.social_value) ??
      "",
    twitter:
      (data?.user_socials &&
        data.user_socials?.length > 0 &&
        data.user_socials.find((social) => social?.social_type === "twitter")
          ?.social_value) ??
      "",
    background_url: data?.background_url,
    avatar_url: data?.avatar_url,
    background: null,
    avatar: null,
    city: data?.locations?.city ?? "",
    country: data?.locations?.country ?? "",
  };

  const [pickColor, setPickColor] = useState(false);

  useEffect(() => {
    document.getElementById("bgAppearance").style.backgroundColor =
      "#" + bgColor;
  }, [bgColor]);

  return (
    <Modal setBio={setBio} title="Edit Bio">
      <Formik
        initialValues={initialValues}
        validationSchema={developerBioValidationSchema}
        onSubmit={async (
          { avatar, avatar_url, background, background_url, ...values },
          { resetForm }
        ) => {
          values.location = values.city + ", " + values.country;

          if (!background_url || background_url.length === 6) {
            background_url = bgColor;
          }
          try {
            let details = {
              ...values,
              updated_at: new Date()
                .toISOString()
                .toLocaleString("en-GB", { timeZone: "UTC" }),
              background_url,
              avatar_url,
            };

            if (avatar) {
              const { path } = await uploadAvatar(avatar, user_id);
              const { publicUrl } = await generateURL(path, "avatars");
              details.avatar_url = publicUrl;
            }

            if (background) {
              const { path } = await uploadBackground(background, user_id);
              const { publicUrl } = await generateURL(path, "backgrounds");
              details.background_url = publicUrl;
            }

            const { error } = await supabase.rpc("update_developer_bio", {
              details,
            });

            if (error) {
              toast.error(`${error.message}`, { position: "top-center" });
            }

            toast.success(`Profile updated`, {
              position: "top-center",
            });
          } catch (error) {
          } finally {
            resetForm({
              values: { ...initialValues, avatar_url, background_url },
            });
            setBio(false);
          }
        }}
      >
        {({ values, errors, handleChange, handleBlur, setFieldValue }) => (
          <Form className="">
            <div className="border-b-[1px] border-gray-500 px-3 md:px-5 py-2 pb-5 flex justify-between sticky left-0 z-30 bg-white bg-opacity-90 right-0 top-0">
              <div className="flex gap-2 items-center">
                <span
                  className="w-8 h-8 hover:bg-gray-200 flex justify-center items-center cursor-pointer rounded"
                  onClick={() => {
                    setBio(false);
                  }}
                >
                  <MdClose size={22} />
                </span>
                <h2 className="font-semibold">Edit Bio</h2>
              </div>
              <button
                className="bg-ablestate-orange text-white py-1 px-4 rounded-md text-sm"
                type="submit"
              >
                Save
              </button>
            </div>
            <div
              className={`bg-[#${bgColor}] w-full md:w-[calc(100-10)] mb-20 h-40 md:h-60 relative flex gap-2 justify-center items-center bg-cover`}
              id="bgAppearance"
              style={{ backgroundImage: `url(${values?.background_url})` }}
            >
              <div className="bg-black bg-opacity-80 hover:bg-opacity-70 text-white rounded-full p-3 cursor-pointer relative overflow-hidden">
                <MdOutlineAddAPhoto size={20} />
                <ImageUpload
                  fileRef="background"
                  urlRef="background_url"
                  inputStyles="absolute top-0 right-0 h-full w-full opacity-0 cursor-pointer"
                  setFieldValue={setFieldValue}
                />
              </div>
              <div
                className="bg-[#d1d5db] hover:bg-opacity-70 text-sm rounded-full p-3 cursor-pointer relative w-[32px] h-[32px] border-2 border-black"
                onClick={(event) => {
                  event.preventDefault();
                  setPickColor((prev) => !prev);
                }}
              >
                <div
                  className={`absolute p-5 bg-white z-20 rounded-md top-[35px] shadow-sm w-[200px] -left-20 md:-left-10 ${
                    pickColor ? "" : "hidden"
                  }`}
                >
                  <h2 className="text-sm mb-3">Background Color</h2>
                  <div className="grid grid-cols-4 justify-center items-center gap-2">
                    <div
                      className="bg-gray-300 rounded-md w-5 h-5"
                      onClick={() => {
                        setBgColor("d1d5db");
                        localStorage.setItem("appearance", "d1d5db");
                      }}
                    ></div>
                    <div
                      className="bg-rose-600 rounded-md w-5 h-5"
                      onClick={() => {
                        setBgColor("f43f5e");
                        localStorage.setItem("appearance", "f43f5e");
                      }}
                    ></div>
                    <div
                      className="bg-orange-600 rounded-md w-5 h-5"
                      onClick={() => {
                        setBgColor("ea580c");
                        localStorage.setItem("appearance", "ea580c");
                      }}
                    ></div>
                    <div
                      className="bg-yellow-400 rounded-md w-5 h-5"
                      onClick={() => {
                        setBgColor("facc15");
                        localStorage.setItem("appearance", "facc15");
                      }}
                    ></div>
                    <div
                      className="bg-green-700 rounded-md w-5 h-5"
                      onClick={() => {
                        setBgColor("15803d");
                        localStorage.setItem("appearance", "15803d");
                      }}
                    ></div>
                    <div
                      className="bg-teal-600 rounded-md w-5 h-5"
                      onClick={() => {
                        setBgColor("0d9488");
                        localStorage.setItem("appearance", "0d9488");
                      }}
                    ></div>
                    <div
                      className="bg-indigo-600 rounded-md w-5 h-5"
                      onClick={() => {
                        setBgColor("4f46e5");
                        localStorage.setItem("appearance", "4f46e5");
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div
                className="bg-black bg-opacity-80 hover:bg-opacity-70 text-white rounded-full p-3 cursor-pointer relative overflow-hidden"
                onClick={(event) => {
                  event.preventDefault();
                  setFieldValue("background", null);
                  setFieldValue("background_url", null);
                }}
              >
                <MdOutlineClose size={20} />
              </div>
              <div className="absolute -bottom-12 md:-bottom-16 mx-2 flex items-end justify-between w-11/12">
                <div
                  className="bg-white w-20 h-20 md:w-28 md:h-28 rounded-full overflow-hidden bg-cover border-2 border-white"
                  style={{ backgroundImage: `url(${values?.avatar_url})` }}
                ></div>
                <div className="bg-black bg-opacity-40 w-20 h-20 md:w-28 md:h-28 border-2 md:border-4 border-white rounded-full z-10 absolute top-0 left-0 flex gap-1 justify-center items-center text-white">
                  <div className="bg-black bg-opacity-80 hover:bg-opacity-70 text-white rounded-full p-2 cursor-pointer relative overflow-hidden">
                    <MdOutlineAddAPhoto size={20} />
                    <ImageUpload
                      fileRef="avatar"
                      urlRef="avatar_url"
                      inputStyles="absolute top-0 right-0 h-full w-full opacity-0 cursor-pointer"
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div
                    className="bg-black bg-opacity-80 hover:bg-opacity-70 text-white rounded-full p-3 cursor-pointer relative overflow-hidden"
                    onClick={(event) => {
                      event.preventDefault();
                      setFieldValue("avatar", null);
                      setFieldValue("avatar_url", null);
                    }}
                  >
                    <MdOutlineClose size={15} />
                  </div>
                </div>
              </div>
            </div>
            <section className="px-5 md:px-10 mb-5">
              <div className="relative rounded">
                <input
                  type="text"
                  id="fullname"
                  name="fullname"
                  className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                  placeholder=" "
                  value={values.fullname}
                  onChange={handleChange("fullname")}
                  onBlur={handleBlur("fullname")}
                />
                <label
                  htmlFor="fullname"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                >
                  Full Name <span className="text-red-400">*</span>
                </label>
                <div className="text-xs text-red-500 h-6 align-middle max-h-fit py-1">
                  {errors?.fullname}
                </div>
              </div>

              <div className="relative rounded">
                <input
                  type="text"
                  id="tagline"
                  name="tagline"
                  className="block rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                  placeholder=" "
                  defaultValue={values.tagline}
                  onChange={handleChange("tagline")}
                  onBlur={handleBlur("tagline")}
                />
                <label
                  htmlFor="tagline"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                >
                  Tagline <span className="text-red-400">*</span>
                </label>
                <div className="text-xs text-red-500 h-6 align-middle max-h-fit py-1">
                  {errors?.tagline}
                </div>
              </div>

              <div className="relative rounded">
                <input
                  type="text"
                  id="handle"
                  name="handle"
                  className="block rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                  placeholder=" "
                  defaultValue={values.handle}
                  onChange={handleChange("handle")}
                  onBlur={handleBlur("handle")}
                />
                <label
                  htmlFor="handle"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                >
                  Handle <span className="text-red-400">*</span>
                </label>
                <div className="text-xs text-red-500 h-6 align-middle max-h-fit py-1">
                  {errors?.handle}
                </div>
              </div>
              {role === "developer" && (
                <div className="relative rounded">
                  <select
                    name=""
                    id="primary_programming_language"
                    className={`block rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer ${
                      errors?.primary_programming_language && "border-red-500"
                    }`}
                    value={values.primary_programming_language}
                    onChange={handleChange("primary_programming_language")}
                    onBlur={handleBlur("primary_programming_language")}
                  >
                    <option value="">Select Primary language</option>
                    {programmingLanguages.map((language, index) => (
                      <option value={language} key={index}>
                        {language}
                      </option>
                    ))}
                  </select>
                  <label
                    htmlFor="primary_programming_language"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                  >
                    Primary Programming Language{" "}
                    <span className="text-red-400">*</span>
                  </label>
                  <div className="text-xs text-red-500 h-6 align-middle max-h-fit py-1">
                    {errors?.primary_programming_language}
                  </div>
                </div>
              )}
              <div className="relative rounded">
                <input
                  type="text"
                  id="phone"
                  className={`block rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer ${
                    errors?.phone && "border-red-500"
                  }`}
                  placeholder=" "
                  defaultValue={values.phone}
                  onChange={handleChange("phone")}
                  onBlur={handleBlur("phone")}
                />
                <label
                  htmlFor="phone"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                >
                  Phone Number ( Int. format ){" "}
                  <span className="text-red-400">*</span>
                </label>
                <div className="text-xs text-red-500 h-6 align-middle max-h-fit py-1">
                  {errors?.phone}
                </div>
              </div>

              <div className="relative rounded">
                <select
                  name=""
                  id="gender"
                  className="block rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                  value={values.gender}
                  onChange={handleChange("gender")}
                  onBlur={handleBlur("gender")}
                >
                  <option value="">Select a gender</option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                </select>
                <label
                  htmlFor="gender"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                >
                  Gender
                </label>
                <div className="text-xs text-red-500 h-6 align-middle max-h-fit py-1">
                  {errors?.gender}
                </div>
              </div>

              <div className="relative rounded">
                <textarea
                  rows="3"
                  id="bio"
                  className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] focus:outline-none focus:ring-0 peer resize-none"
                  placeholder=" "
                  defaultValue={values.bio}
                  onChange={handleChange("bio")}
                />
                <label
                  htmlFor="bio"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                >
                  Bio <span className="text-red-400">*</span>
                </label>
                <div className="text-xs text-red-500 h-6 align-middle max-h-fit py-1">
                  {errors?.bio}
                </div>
              </div>

              {role === "developer" && (
                <>
                  <div className="relative rounded">
                    <input
                      type="text"
                      id="github"
                      className="block rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 border[.9px] focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                      placeholder=" "
                      value={values.github}
                      onChange={handleChange("github")}
                      onBlur={handleBlur("github")}
                    />
                    <label
                      htmlFor="github"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                    >
                      Github Username <span className="text-red-400">*</span>
                    </label>
                    <div className="text-xs text-red-500 h-6 align-middle max-h-fit py-1">
                      {errors?.github}
                    </div>
                  </div>
                  <div className="relative rounded">
                    <input
                      type="text"
                      id="linkedin"
                      className="block rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 border[.9px] focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                      placeholder=" "
                      value={values.linkedin}
                      onChange={handleChange("linkedin")}
                      onBlur={handleBlur("linkedin")}
                    />
                    <label
                      htmlFor="linkedin"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                    >
                      LinkedIn
                    </label>
                    <div className="text-xs text-red-500 h-6 align-middle max-h-fit py-1">
                      {errors?.linkedin}
                    </div>
                  </div>

                  <div className="relative rounded">
                    <input
                      type="text"
                      id="twitter"
                      className="block rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 border[.9px] focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                      placeholder=" "
                      value={values.twitter}
                      onChange={handleChange("twitter")}
                      onBlur={handleBlur("twitter")}
                    />
                    <label
                      htmlFor="twitter"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                    >
                      Twitter Username
                    </label>
                    <div className="text-xs text-red-500 h-6 align-middle max-h-fit py-1">
                      {errors?.twitter}
                    </div>
                  </div>
                </>
              )}

              <div className="relative rounded">
                <select
                  className="block rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                  value={values.country}
                  onChange={handleChange("country")}
                  onBlur={handleBlur("country")}
                >
                  <option value=""></option>
                  {countryList &&
                    countryList.length > 0 &&
                    countryList.map((country, index) => (
                      <option value={country} key={index}>
                        {country}
                      </option>
                    ))}
                </select>
                <label
                  htmlFor="country"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                >
                  Country <span className="text-red-400">*</span>
                </label>
                <div className="text-xs text-red-500 h-6 align-middle max-h-fit py-1">
                  {errors?.country}
                </div>
              </div>
              <div className="relative rounded">
                <input
                  type="text"
                  id="city"
                  className="block rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 border[.9px] focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                  placeholder=" "
                  value={values.city}
                  onChange={handleChange("city")}
                  onBlur={handleBlur("city")}
                />
                <label
                  htmlFor="city"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                >
                  City <span className="text-red-400">*</span>
                </label>
                <div className="text-xs text-red-500 h-6 align-middle max-h-fit py-1">
                  {errors?.city}
                </div>
              </div>
              {role === "developer" && (
                <div className="relative rounded">
                  <select
                    className="block rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                    value={values.human_language}
                    onChange={handleChange("human_language")}
                    onBlur={handleBlur("human_language")}
                  >
                    <option value="">Select a language</option>
                    {humanLanguagesList &&
                      humanLanguagesList.length > 0 &&
                      humanLanguagesList.map((language, index) => (
                        <option value={language} key={index}>
                          {language}
                        </option>
                      ))}
                  </select>
                  <label
                    htmlFor="language"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                  >
                    Primary Human Language
                  </label>
                  <div className="text-xs text-red-500 h-6 align-middle max-h-fit py-1">
                    {errors?.human_language}
                  </div>
                </div>
              )}
            </section>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
