import {
  MdClose,
  MdOutlineAddAPhoto,
  MdOutlineClose,
  MdOutlineArrowBackIos,
  MdOutlineModeEdit,
} from "react-icons/md";
import { Formik, Form } from "formik";
import { supabase } from "helpers";
import ImageUpload from "components/to-be-cleaned/ImageUpload";
import { useAuth } from "components/auth/AuthContext";
import { generateURL } from "helpers/utils/downloadFiles";
import { uploadLogo } from "helpers/utils/uploadFiles";
import { useState, useEffect } from "react";
import { Modal } from "../Modal";

export function EditCompanyModal({ setEditCompany, data }) {
  const [edit, setEdit] = useState(null);
  const {
    user: { id: user_id },
  } = useAuth();

  useEffect(() => {}, []);

  return (
    <Modal setBio={setEditCompany}>
      <div className="border-b-[1px] border-gray-500 px-5 py-2 pb-5 flex justify-between sticky left-0 z-20 bg-white bg-opacity-80 right-0 top-0">
        {edit ? (
          <div className="flex gap-2 items-center">
            <span
              className="w-8 h-8 hover:bg-gray-200 flex justify-center items-center cursor-pointer rounded"
              onClick={() => {
                setEdit(false);
              }}
            >
              <MdOutlineArrowBackIos size={22} />
            </span>
            <h2 className="">{edit.company_name}</h2>
          </div>
        ) : (
          <div className="flex gap-2 items-center">
            <span
              className="w-8 h-8 hover:bg-gray-200 flex justify-center items-center cursor-pointer rounded"
              onClick={() => {
                setEditCompany(false);
              }}
            >
              <MdClose size={22} />
            </span>
            <h2 className="font-semibold">Edit Info</h2>
          </div>
        )}

        {edit && (
          <button
            className="bg-ablestate-orange text-white py-1 px-4 rounded-md text-sm"
            type="submit"
          >
            Save
          </button>
        )}
      </div>

      {edit && (
        <Formik
          initialValues={{
            company_name: edit?.company_name ?? "",
            company_email: "",
            phone_number: "",
            description: edit?.description ?? "",
            tagline: edit?.tagline ?? "",
            industry: edit?.industry ?? "",
            logo: null,
            logo_url: edit?.logo_url ?? "",
            handle: edit?.company_handles.handle ?? "",
            location: edit?.locations ?? "",
          }}
          enableReinitialize
          onSubmit={async ({ logo, background, ...values }, { resetForm }) => {
            try {
              console.log(values);
              console.log("something good");
              if (logo) {
                const { path } = await uploadLogo(logo, user_id);
                const { publicUrl } = await generateURL(path, "avatars");
                console.log("New logo: ", publicUrl);
                values.logo_url = publicUrl;
                console.log(values);
              }

              const { error } = await supabase.rpc("update_company_details", {
                details: JSON.stringify(values),
              });
              if (error) throw error;
            } catch (error) {}
          }}
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form className="">
              <>
                <h2 className="mx-10">Upload Logo</h2>
                <div className="mx-10 mb-5 flex items-end justify-between h-20 w-20 md:h-28 md:w-28 rounded-full">
                  <div
                    className="w-full h-full rounded-full overflow-hidden bg-cover border-2 border-white relative flex justify-center items-center bg-gray-200"
                    style={{ backgroundImage: `url(${values?.logo_url})` }}
                  >
                    <button className="flex bg-gray-900 w-8 h-8 rounded-full justify-center items-center text-white opacity-50 relative mr-2 cursor-pointer">
                      <ImageUpload
                        fileRef="logo"
                        urlRef="logo_url"
                        inputStyles="absolute top-0 right-0 h-full w-full opacity-0 rounded-full"
                        setFieldValue={setFieldValue}
                      />
                      <MdOutlineAddAPhoto />
                    </button>
                    <div
                      className="flex bg-gray-900 w-8 h-8 rounded-full justify-center items-center text-white opacity-50 cursor-pointer"
                      onClick={() => {
                        setFieldValue("logo", "");
                        setFieldValue("logo_url", "");
                      }}
                    >
                      <MdOutlineClose />
                    </div>
                  </div>
                </div>
                <section className="px-10 mb-5">
                  <div className="relative mb-5 rounded">
                    <input
                      type="text"
                      id="company_name"
                      name="company_name"
                      className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                      placeholder=" "
                      value={values?.company_name}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="comapany_name"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                    >
                      Company Name <span className="text-red-400">*</span>
                    </label>
                  </div>
                  <div className="relative mb-5 rounded">
                    <input
                      type="text"
                      id="tagline"
                      name="tagline"
                      className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                      placeholder=" "
                      defaultValue={values?.tagline}
                      onChange={handleChange("tagline")}
                      maxLength={140}
                    />
                    <label
                      htmlFor="tagline"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                    >
                      Tagline <span className="text-red-400">*</span>
                    </label>
                    {values.tagline.length === 140 && (
                      <span className="text-xs text-red-500">
                        maximum character length reached
                      </span>
                    )}
                  </div>
                  <div className="relative mb-3 rounded">
                    <textarea
                      rows="3"
                      id="description"
                      className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] focus:outline-none focus:ring-0 peer resize-none"
                      placeholder=" "
                      defaultValue={values?.description}
                      onChange={handleChange("description")}
                    />
                    <label
                      htmlFor="description"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                    >
                      Description <span className="text-red-400">*</span>
                    </label>
                  </div>
                  <div className="relative mb-5 rounded">
                    <input
                      type="text"
                      id="industry"
                      name="industry"
                      className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                      placeholder=" "
                      defaultValue={values?.industry}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="tagline"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                    >
                      Industry <span className="text-red-400">*</span>
                    </label>
                  </div>
                  <div className="relative mb-5 rounded">
                    <input
                      type="text"
                      id="company_email"
                      name="company_email"
                      className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                      placeholder=" "
                      defaultValue={values?.company_email}
                      onChange={handleChange("company_email")}
                    />
                    <label
                      htmlFor="company_email"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                    >
                      Company Email <span className="text-red-400">*</span>
                    </label>
                  </div>
                  <div className="relative mb-5 rounded">
                    <input
                      type="text"
                      id="phone_number"
                      name="phone_number"
                      className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                      placeholder=" "
                      defaultValue={values?.phone_number}
                      onChange={handleChange("phone_number")}
                    />
                    <label
                      htmlFor="phone_number"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                    >
                      Phone Number <span className="text-red-400">*</span>
                    </label>
                  </div>
                  <div className="relative mb-5 rounded">
                    <input
                      type="text"
                      id="location"
                      name="location"
                      className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                      placeholder=" "
                      defaultValue={values?.location}
                      onChange={handleChange("location")}
                    />
                    <label
                      htmlFor="location"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                    >
                      Location <span className="text-red-400">*</span>
                    </label>
                  </div>

                  <div className="relative mb-10 rounded">
                    <input
                      type="text"
                      id="handle"
                      className="block rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 focus:border-[#f36c32] focus:outline-none focus:ring-0 peer"
                      placeholder=" "
                      defaultValue={values?.handle}
                      onChange={handleChange("handle")}
                    />
                    <label
                      htmlFor="handle"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                    >
                      Handle <span className="text-red-400">*</span>
                    </label>
                  </div>
                </section>
              </>
            </Form>
          )}
        </Formik>
      )}
      {!edit && (
        <div className="px-3 md:px-8 py-5">
          {data &&
            data?.length > 0 &&
            data.map((company, index) => (
              <div className="border-b-[1px] py-3" key={index}>
                <div className="flex justify-between">
                  <h2 className="font-bold">
                    {company.companies.company_name}
                  </h2>
                  <MdOutlineModeEdit
                    size={20}
                    className="cursor-pointer"
                    onClick={() => setEdit(company.companies)}
                  />
                </div>
              </div>
            ))}
        </div>
      )}
    </Modal>
  );
}
