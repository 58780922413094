import { Criteria } from "../Criteria";
import { Star } from "../Icons/Star";
import { IoMdPerson } from "react-icons/io";
export function RatingCard({ developer, developers, setDevelopers }) {
  return (
    <div className="border-b-2 px-2 py-2 w-full">
      <div className="flex gap-4 mb-4">
        <div className="w-[40px] h-[40px] overflow-hidden flex justify-center items-center rounded-full">
          {developer?.avatar_url ? (
            <img src={`${developer.avatar_url}`} width={50} alt="developer" />
          ) : (
            <div className="border-[1px] border-gray-300 w-[40px] h-[40px] rounded-full bg-gray-100 flex justify-center items-center">
              <IoMdPerson size={25} className="text-gray-300" />
            </div>
          )}
        </div>
        <div>
          <h2 className="text-lg font-medium">{developer.fullname}</h2>
          <p className="text-sm font-light text-gray-500">{developer.tagline}</p>
        </div>
      </div>
      <div className="flex flex-col gap-2 mx-4 px-4">
        <Criteria
          label="Timekeeping"
          rating={developer.dev_rating[0]?.time_keeping}
          dev_rating={developer.dev_rating[0]}
          criteria_id="time_keeping"
          developers={developers}
          developer_id={developer && developer.id}
          setDevelopers={setDevelopers}
        />
        <Criteria
          label="Effective Communication"
          rating={developer.dev_rating[0]?.communication}
          dev_rating={developer.dev_rating[0]}
          criteria_id="communication"
          developers={developers}
          developer_id={developer && developer.id}
          setDevelopers={setDevelopers}
        />
        <Criteria
          label="Technical Efficiency"
          rating={developer.dev_rating[0]?.technical_effectiveness}
          dev_rating={developer.dev_rating[0]}
          criteria_id="technical_effectiveness"
          developers={developers}
          developer_id={developer && developer.id}
          setDevelopers={setDevelopers}
        />
        <Criteria
          label="Job Focus"
          rating={developer.dev_rating[0]?.job_focus}
          dev_rating={developer.dev_rating[0]}
          criteria_id="job_focus"
          developers={developers}
          developer_id={developer && developer.id}
          setDevelopers={setDevelopers}
        />
        <Criteria
          label="Teamwork and collaborativeness"
          rating={developer.dev_rating[0]?.team_work}
          dev_rating={developer.dev_rating[0]}
          criteria_id="team_work"
          developers={developers}
          developer_id={developer && developer.id}
          setDevelopers={setDevelopers}
        />
      </div>
      {/* {developer.dev_rating && developer.dev_rating.length > 0 && ( */}
      {/* )} */}

      <div className="flex justify-between mt-4 mb-2">
        <p></p>
        <p className="font-light">Average Rating</p>
        <div className="flex gap-1 items-center">
          <p>
            {(
              ((developer.dev_rating[0]?.time_keeping || 0) +
                (developer.dev_rating[0]?.communication || 0) +
                (developer.dev_rating[0]?.technical_effectiveness || 0) +
                (developer.dev_rating[0]?.job_focus || 0) +
                (developer.dev_rating[0]?.team_work || 0)) /
              5
            ).toFixed(1)}
          </p>
          <Star checked={true} width="16" height="16" />
        </div>
      </div>
    </div>
  );
}
