import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import PropTypes from "prop-types";
import { Formik, Form, ErrorMessage, FieldArray, Field } from "formik";

export function Part2({ vendors, initialValues, setInitialValues, setOpenTab }) {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, resetForm) => {
        console.log(values);
        setInitialValues(values);
        setOpenTab(2);
        // addJob(values, resetForm);
      }}
    >
      {({ values, setFieldValue, handleChange }) => (
        <Form>
          <FieldArray
            name="stages"
            render={(arrayHelpers) => (
              <div>
                {values.stages &&
                  values.stages.length > 0 &&
                  values.stages.map((stage, index) => (
                    <div key={index}>
                      <main className="grid md:grid-cols-2 gap-x-20 gap-y-5 mt-5 my-3 outline outline-1 outline-gray-100 px-2 py-2 w-full">
                        <div className="relative rounded w-full">
                          <Field
                            type="text"
                            id={`stage_name-${index}`}
                            name={`stages.${index}.stage_name`}
                            className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] focus:outline-none focus:ring-0 peer"
                            placeholder=" "
                          />
                          <label
                            htmlFor={`stage_name-${index}`}
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                          >
                            Name <span className="text-red-400">*</span>
                          </label>
                        </div>
                        <div className="relative rounded w-full">
                          <Field
                            type="text"
                            id={`stage_description-${index}`}
                            defaultValue={values.stage_description}
                            name={`stages.${index}.stage_description`}
                            className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] focus:outline-none focus:ring-0 peer"
                            placeholder=" "
                          />
                          <label
                            htmlFor={`stage_description-${index}`}
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                          >
                            Description <span className="text-red-400">*</span>
                          </label>
                        </div>
                        <div className="relative rounded w-full">
                          <Field
                            as="select"
                            type="text"
                            id={`vendor-${index}`}
                            name={`stages.${index}.vendor`}
                            className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] focus:outline-none focus:ring-0 peer"
                            placeholder=" "
                          >
                            <option value="">Select Vendor</option>
                            {vendors &&
                              vendors?.length > 0 &&
                              vendors.map((vendor, index) => {
                                return (
                                  <option key={index} value={vendor.vendor_id}>
                                    {vendor.name}
                                  </option>
                                );
                              })}
                          </Field>

                          <label
                            htmlFor={`vendor-${index}`}
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                          >
                            Vendor <span className="text-red-400">*</span>
                          </label>
                        </div>
                        <div className="relative rounded w-full">
                          <Field
                            type="text"
                            id={`type-${index}`}
                            name={`stages.${index}.type`}
                            className="rounded px-2.5 pb-2.5 pt-5 w-full text-sm focus:border-[#f36c32] text-gray-900 border-[.9px] focus:outline-none focus:ring-0 peer"
                            placeholder=" "
                          />

                          <label
                            htmlFor={`type-${index}`}
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:text-[#f36c32] peer-focus:-translate-y-4"
                          >
                            Type <span className="text-red-400">*</span>
                          </label>
                        </div>
                      </main>

                      {values.stages?.length == index + 1 && (
                        <section className="px-3 py-2 flex gap-2 justify-end">
                          <button
                            className=" bg-gray-100 rounded w-10 h-10 flex items-center justify-center text-[#D20202] cursor-pointer"
                            onClick={() => {
                              if (values.stages.length > 1) {
                                arrayHelpers.remove(index);
                              }
                            }}
                            type="button"
                          >
                            <AiFillMinusCircle size={20} />
                          </button>
                          <button
                            className=" bg-gray-100 rounded w-10 h-10 flex items-center justify-center text-[#008000] cursor-pointer"
                            type="button"
                            onClick={() =>
                              arrayHelpers.push({
                                name: "",
                                description: "",
                                vendor: "",
                                type: "",
                              })
                            }
                          >
                            <AiFillPlusCircle size={20} />
                          </button>
                        </section>
                      )}
                    </div>
                  ))}
              </div>
            )}
          />
          <div className="text-sm flex gap-2 w-full justify-between pb-5">
            <button
              className="px-3 py-2 outline outline-1 rounded"
              type="button"
              onClick={() => {
                setOpenTab(0);
              }}
            >
              Back
            </button>
            <button className="px-3 py-2 outline outline-1 rounded" type="submit">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

Part2.propTypes = {
  values: PropTypes.object,
};
